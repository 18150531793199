import styled from "styled-components";
import { DESKTOP_1024, TABLET_768 } from "../../styles/sizes";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  max-width: 800px;

  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`;

export const WiperItem = styled.div`
  background: red;
  width: 250px;
`;

export const PsgContainer = styled.div`
  display: flex;
  height: 95px;
  align-self: center;

  @media (max-width: ${TABLET_768}px) {
    height: 100%;
  }
`;

export const Image = styled.img`
  height: 60%;
  align-self: center;
  margin-top: 30px;
  @media (max-width: ${TABLET_768}px) {
    margin-bottom: 30px;
    width: 200px;
    height: auto;
  }
`;
