import styled from 'styled-components'
import { DESKTOP_1200, TABLET_1023, TABLET_800, MOBILE_460, MOBILE_380, DESKTOP_1024, DESKTOP_1366, } from '../../styles/sizes';
import { Link } from "gatsby"

export const Container = styled.div<IWorkProps>`
    width: auto;
    height: 300px;
    overflow: hidden;
    position: relative;
    display: flex;
    transition: transform 0.5s;
    place-items: end;
    align-items: flex-end;
    background-color: white;
    grid-row-end: span 3;
    grid-row-start: ${props => {
        const index = props.indexPosition;
        if (index === 'index0' || index === "index2") {
            return '2';
        } else if (index === 'index1') {
            return '1';
        } else {
            return 'auto';
        }
    }};
    @media (max-width: ${DESKTOP_1366}px) {
        height: 250px;
    };
    @media (max-width: ${DESKTOP_1200}px) {
        height: 200px;
    };
    @media (max-width: ${TABLET_1023}px) {
        ${props => {
            const index = props.indexPosition;
            if (index === 'index0' && props.isSeeMore) {
                return `grid-row-start: 1;`;
            } else if (index === 'index0') {
                return 'grid-row-start: 2;';
            } else if (index === 'index1' && props.isSeeMore) {
                return 'grid-row-start: 3;';
            } else if (index === 'index1') {
                return 'grid-row-start: 1;';
            } else {
                return 'grid-row-start: auto;';
            }
        }}
        height: 250px;
        top: ${props => props.isSeeMore ? "-125px;" : "0px;"};
    };
    @media (max-width: ${TABLET_800}px) {
        height: 300px;
        width: 100%;
        grid-row-start: ${props => {
            const index = props.indexPosition;
            if (index === 'index0' && props.isSeeMore) {
                return `1;`;
            } else if (index === 'index0') {
                return '1;';
            } else {
                return 'auto;';
            }
        }};
        grid-column-start: 1;
        grid-column-end: 2;
        top: 0;
    }
    @media (max-width: ${MOBILE_460}px) {
        height: 280px;
    }
    @media (max-width: ${MOBILE_380}px) {
        height: 250px;
    }
    & :hover, &:active, &:focus {
            picture > img {
            transform: scale(calc(1.1));
        }
    }
    picture > img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        transform-origin: bottom left;
        transition: transform 0.5s !important;
        }
`
export const Redirect = styled(Link)`
    text-decoration: none;


`
export const Mask = styled.div`
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, #00000000 0%, #0000009B 50%, #000000eb 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0;
  z-index: 1;
`
export const Picture = styled.picture<IWorkProps>`

`
export const TextContainer = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 0 20px 20px;
    position: absolute;
    bottom: 0;
    z-index: 2;
    @media (max-width: ${DESKTOP_1200}px) {
        padding: 0 15px 15px;
    }
`
export const CardTitle = styled.div<IWorkProps>`
    padding: 0 0 10px;
    text-align: left;
    color: white;
    ${(props) => props.longText ? `font: bold 28px/34px "Montserrat";` : `font: bold 35px/45px "Montserrat";`}
    @media (max-width: ${DESKTOP_1200}px) {
        ${(props) => props.longText ? `font: bold 22px/30px "Montserrat";` : `font: bold 30px/33px "Montserrat";`}
    }
    @media (max-width: ${MOBILE_460}px) {
        ${(props) => props.longText ? `font: bold 26px/30px "Montserrat";` : `font: bold 30px/35px "Montserrat";`}
    }
`
export const CardText = styled.div`
    text-align: left;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3;
    -webkit-box-orient: vertical;
    @media (max-width: ${DESKTOP_1200}px) {
        font: normal 14px/16px "Montserrat";
    }
`

export const GatsbyImageContainer = styled.div`

`

interface IWorkProps {
    indexPosition?: string,
    hover?: boolean,
    isSeeMore?: boolean;
    longText?: boolean;
}
