import React, { Children } from 'react'
import bodymovin, { AnimationItem } from 'lottie-web'
import { useInView } from 'react-hook-inview'

import { TitleContainer, Title } from './styles'

export const AnimatedTitle: React.FC<IProps> = (props: IProps) => {
  const [ref, isVisible] = useInView({
    threshold: 0,
    rootMargin: props.offset || '0%',
    onEnter: () => { /* console.log('enter') */ },
    unobserveOnEnter: true,
  })
  return (
  <div ref={ref}>
     {Children.map(Children.toArray(props.children), (child, index) => {
       return(
        <TitleContainer heightTablet={props.heightTablet} height={props.height} heightMobile={props.heightMobile}>
                <Title delay={(props.lineDelay ? index * props.lineDelay : 0).toString() + 's'} isVisible={isVisible}>{child}</Title>
        </TitleContainer>
        )
     })}
  </div>
  )
}

interface IProps {
  children: any,
  offset?: string,
  lineDelay?: number,
  height?: string,
  heightMobile?: string,
  paddingTop?: string,
  paddingTopMobile?: string;
  heightTablet?: string;
}
