import styled from 'styled-components'

export const LottieContainer = styled.div<ILottieContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

export const SegmentedLottieContainer = styled.div<ILottieContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`
interface ILottieContainerProps {
    height: string,
    width: string
}
