import React, { useEffect, useRef, useState } from 'react';
import { OnView } from './onViewTrigger.styles';
import { OnViewTriggerProps } from './onViewTrigger.types';

const OnViewTrigger: React.FC<OnViewTriggerProps> = ({
  onView,
  finishOnView,
  children,
  TriggerPosition,
  maxTriggerPosition,
  animationOnView,
  style,
}: OnViewTriggerProps) => {
  const containerRef = useRef<HTMLInputElement>(null);
  const [firstPlay, setFirstPlay] = useState(true);

  const getContainerDistanceToViewport = () => {
    if (containerRef && containerRef.current) {
      const { top, height } = containerRef.current.getBoundingClientRect();
      // Calculate current view percentage
      const current = window.innerHeight - top;
      const max = window.innerHeight + height - 120;
      return current / max;
    }
    return -1;
  };
  useEffect(() => {
    // define lottie animation
    if (containerRef && containerRef.current) {
      const onScroll = () => {
        if (containerRef && containerRef.current) {
          const position = getContainerDistanceToViewport();
          if (TriggerPosition && position >= TriggerPosition &&  position < maxTriggerPosition ) {
            if (onView) onView();
            //document.removeEventListener('scroll', onScroll);
            //setFirstPlay(false);
          }
          if (TriggerPosition && (position > maxTriggerPosition || position < TriggerPosition )){
            if (finishOnView) finishOnView();
            //document.removeEventListener('scroll', onScroll);
            //setFirstPlay(false);
          }
        }
      };

      document.addEventListener('scroll', onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, setFirstPlay, onView, firstPlay]);

  return (
    <OnView style={style} ref={containerRef} inView={!firstPlay} animationOnView={animationOnView}>
      {children && children}
    </OnView>
  );
};

const defaultProps = {
  TriggerPosition: 0.5,
  onView: () => {},
};

OnViewTrigger.defaultProps = defaultProps;

export default OnViewTrigger;
