import { CSSProperties } from 'styled-components';

export enum AnimationOnView {
  mobileAppear = 'mobile-appear',
}

export interface OnViewTriggerProps {
  onView?: () => void;
  finishOnView?:() => void;
  TriggerPosition?: number;
  maxTriggerPosition?: number
  children?: JSX.Element | false;
  animationOnView?: AnimationOnView;
  style?: CSSProperties;
}

export interface OnViewProps {
  animationOnView?: AnimationOnView;
  inView?: boolean;
}
