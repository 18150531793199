import React, { useEffect, useRef } from 'react'
import bodymovin from 'lottie-web'

import styled from "styled-components";

export const LottieBackground = styled.div`
  width: 35vw;
`
export const LoadingDots: React.FC<IProps> = (props: IProps) => {
  const containerRef = useRef(null)

  useEffect(() => {
    // define lottie animation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const anim = bodymovin.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      autoplay: false,
      loop: true,
      animationData: props.animation
    })
    anim.playSegments([0, 55], true)
  }, [])

  return (
    <LottieBackground ref={containerRef} />
  )
}

interface IProps {
  animation: any,
}