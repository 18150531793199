import styled, { css, keyframes } from 'styled-components';
import { TABLET_740, TABLET_992 } from '../../styles/sizes';

export const LogosWrapper = styled.div``;

export const LogosTitle = styled.div`
  font: normal normal bold 30px/42px Montserrat;
  color: #FFFFFF;
  font-weight: bold;

  @media (max-width: ${TABLET_740}px) {
    align-self: flex-start;
    text-align: center;
    font-size: 24px;
    line-height: 44px;
  }
`;

export const Filter = styled.div<{invert?: boolean}>`
width: 15%;
height: 230px;
background: red;
position: absolute;
z-index: 200;
left: 5px;
background: rgb(26,26,29);
background: linear-gradient(90deg, rgba(26,26,29,1) 50%, rgba(26,26,29,0) 100%);

${(props) => props.invert && `
left: unset;
right: 10px;
background: rgb(26,26,29);
background: linear-gradient(90deg, rgba(26,26,29,0) 0%, rgba(26,26,29,1) 55%);
`}
`;

export const MobileContainer = styled.div`
  display: block;
  width: 100%;
`;

export const DesktopContainer = styled.div`
  display: block;
  width: 100%;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const LogosCarrouselDesktop = styled.div`
  padding-top: 40px;
  justify-content: center;
  margin: auto;
  user-select: none;

  .swiper-slide {
    display: flex;
    height: auto !important;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const LogosCarrouselMobile = styled.div`
  padding-top: 40px;
  justify-content: center;
  margin: auto;
  user-select: none;

  .swiper-slide {
    display: flex;
    height: auto !important;
    align-items: center;
    justify-content: center;
  }
`;

export const scrollAnimation = keyframes`
0% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}
100% {
  transform: translate3d(-50%, 0, 0);
}

`;

export const SlideTrack = styled.div`
  animation: ${scrollAnimation} 35s linear infinite;
  display: flex;
  width: calc(250px * 14);
  overflow: hidden;
`;

export const scrollRightAnimation = keyframes`
0% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}
100% {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

`;


export const LogosWrapperMobile = styled.div<IAnimationDirection>`
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  ${({ direction }) => !direction && "transform: rotate(180deg);"}

  &::before,
  &::after {
    @include white-gradient;
    content: '';
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;



export const Slide = styled.div`
  width: 250px;
`;

export const Image = styled.img<IAnimationDirection>`
  height: 60px;
  width: 150px;
  display: flex;
  ${({ direction }) => !direction && "transform: rotate(180deg);"}
  filter: brightness(2);
  justify-content: center;
  div {
    max-height: 90px;
  }
  img {
    object-fit: contain !important;
    max-width: 150px;
    max-height: 90px;
  }
`

interface IAnimationDirection {
  direction: boolean;
}