import React, { useState, useRef, useEffect } from 'react';
import {CleanStringAndCovertToArray, StringToArray} from '../../helpers/CategoriesHelper'
import { HoverableCard } from '../../components/HoverableCard';

import {RelatedContainer, WorksSectionMobile, Title, Container} from './styles';

export const RelatedWork: React.FunctionComponent<IRelatedWork> = (props:IRelatedWork) => {
    const { ownCategories, allWorks, id, Project_Title, industry } = props
    const [hover, setHover] = useState<number>(-1);
    const [relatedWork, setRelatedWork] = useState<Array<any>>([]);

    const categoriesArray = StringToArray(ownCategories)

    const maxContent = 6;

    useEffect(() => {
        const otherProjects = allWorks.filter(work=> work.attributes?.Main_Project_Name?.toLowerCase() == Project_Title.toLowerCase())
        otherProjects.length == 0 ? filterRelatedWork() : setRelatedWork(otherProjects)
    }, []);


    const returnRow = (index: number) => {
       return  (index == 0 || index == 1) ? `index${index}` : 'others'
    }

    const filterRelatedWork = () => {
        let orderWorkByCategoryRelationPoints = CleanStringAndCovertToArray(allWorks)
        orderWorkByCategoryRelationPoints = orderByRelationPoints(orderWorkByCategoryRelationPoints);   
        setRelatedWork(orderWorkByCategoryRelationPoints.slice(0, maxContent));
    }

    const orderByRelationPoints = (array:any) => { 
        array.map((work: any, index: number) => {
            let relationPoints = 0
                categoriesArray.map((item: any) => {
                    if(work.attributes.Categories.includes(item)){
                        relationPoints++
                    }
                })
                if(work.attributes.Industry === industry){
                    relationPoints += 3;
                }
            work["relationPoints"] = relationPoints;
            //
        })
        array.sort((a:any, b:any) => (a.relationPoints > b.relationPoints) ? -1 : 1)
        return array;
    }


    const countRef = useRef(hover);
    countRef.current = hover;

    const handleMouseLeave = (workId: number) => {
        const timer = setTimeout(() => {
            if(countRef.current === workId){
                setHover(-1)
            }
        return() => clearTimeout(timer);
        }, 300);
    }
    const handleMouseEnter = (index: number) => {
        setHover(index)
    }

  return (
    <Container> 
        <RelatedContainer>
            <Title>{relatedWork.length> 0 ? 'Other Projects' : 'Related Work'}</Title>
            <WorksSectionMobile>
                { 
                    relatedWork.map((item: any, index: number) => {
                        return (
                            <HoverableCard isFromWorkTemplate dataProps={item.attributes} id={item.id} key={item.attributes.Project_Title} indexPosition={returnRow(index)} onMouseEnter={() => {handleMouseEnter(index)}} onMouseLeave={() => handleMouseLeave(index)} hover={hover === index + 1}  />
                        );
                    })
                }
            </WorksSectionMobile>
        </RelatedContainer>
    </Container>
  )
}
 
interface IRelatedWork {
    ownCategories?: any,
    industry?: any 
    allWorks?: any,
    id?: number,
    has_subProjects?: boolean
  }