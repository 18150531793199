import React, { useEffect, useRef, useState } from "react";
import bodymovin, { AnimationItem } from "lottie-web";

import VisionLottie from "./assets/our-vision.json";
import Impact from "./assets/impact_icon.svg";
import Commitment from "./assets/commitment_icon.svg";
import Technology from "./assets/technology_icon.svg";

import {
  Text,
  Container,
  Title,
  LottieInfoContainer,
  InfoCardsContainer,
  SegmentedLottieContainer,
} from "./styles";
import { Card } from "./card";
import { CultureContainer } from "../../styles/commons";

export const OurVision = () => {
  const containerRef = useRef(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);
  const [cardNumber, setcardNumber] = useState<string>("");

  useEffect(() => {
    const anim = bodymovin.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: false,
      loop: false,
      animationData: VisionLottie,
    });
    setAnimation(anim);
  }, []);

  useEffect(() => {
    const cardOrder = ["first", "second", "third"];

    const changeCardAutomatically = () => {
      const currentCardIndex = cardOrder.indexOf(cardNumber);
      const nextCardIndex = (currentCardIndex + 1) % cardOrder.length;
      setcardNumber(cardOrder[nextCardIndex]);
    };

    const intervalId = setInterval(() => {
      changeCardAutomatically();
    }, 11000);

    return () => clearInterval(intervalId);
  }, [cardNumber]);

  useEffect(() => {
    switch (cardNumber) {
      case "first": {
        animation.playSegments([20, 80], true);
        break;
      }
      case "second": {
        animation.playSegments([100, 170], true);
        break;
      }
      case "third": {
        animation.playSegments([190, 240], true);
        break;
      }
      default: {
      }
    }
  }, [cardNumber, animation]);

  const onClick = (card: string) => {
    setcardNumber(card);
  };

  return (
    <Container>
      <CultureContainer paddingTop="70px" paddingTopTablet={"20px"}>
        <Title>Our Vision for a Better World</Title>
        <Text>
          We are more than just a digital agency. We are a team committed to the
          idea that every action counts in creating a more sustainable and
          prosperous world.
        </Text>
        <LottieInfoContainer>
          <SegmentedLottieContainer
            height="430px"
            width="475px"
            className={"lottie--container"}
            ref={containerRef}
            key={undefined}
          />
          <InfoCardsContainer>
            <div>
              <Card
                title="Impact Through Collaboration"
                text="We not only help our clients achieve their digital goals, but we
                also contribute to economic growth and job generation across
                various industries. We believe in the power of collaboration to
                drive meaningful change and sustainable growth."
                onClick={() => onClick("first")}
                cardNumber="first"
                currentCard={cardNumber}
                image={Impact}
              />
            </div>
            <div>
              <Card
                title="Technology for a Brighter Future"
                text="Technology plays a crucial role in shaping a more sustainable world, and we strive to use it responsibly and efficiently. Our digital processes are optimized to minimize resource and energy consumption, thus contributing to reducing our environmental footprint."
                onClick={() => onClick("second")}
                cardNumber="second"
                currentCard={cardNumber}
                image={Commitment}
              />
            </div>
            <div>
              <Card
                title="Commitment to Social Responsibility"
                text="We strive to go the extra mile to make a positive difference in our communities. We engage in charitable donations and community projects, demonstrating our commitment to a fairer and more equitable world."
                onClick={() => onClick("third")}
                cardNumber="third"
                currentCard={cardNumber}
                image={Technology}
              />
            </div>
          </InfoCardsContainer>
        </LottieInfoContainer>
      </CultureContainer>
    </Container>
  );
};