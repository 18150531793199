import React from 'react'

import { Topbar } from './Topbar'
import { Footer } from './Footer'
import { GlobalStyles } from '../../globalStyles'

import { LayoutContainer } from './styles'

export const Layout: React.FC<IProps> = (props: IProps) => {
  const {background, initialTextColor, backgroundColor, landing, privacyPolicy, children}=props;
  return (
    <>
      <GlobalStyles/>
      <LayoutContainer background={background}>
        <Topbar privacyPolicy={privacyPolicy} initialTextColor={initialTextColor} landing={landing} backgroundColor={backgroundColor}/>
          {children}
        <Footer />
      </LayoutContainer>
    </>
  )
}

interface IProps {
  background?: string;
  initialTextColor?: string;
  children?: any;
  landing?: string | "home";
  backgroundColor?: string;
  privacyPolicy?: boolean;
}
