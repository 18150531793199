import styled from "styled-components";
import { DESKTOP_1280 } from "../../styles/sizes";
import { LottieProps } from "./stats.types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1A1A1D 0% 0% no-repeat padding-box;
`;

export const NoWrapText = styled.span`
text-wrap: nowrap;
`;

export const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;

  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: column;
    align-self: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: column;
  }
`;

export const StatNumber = styled.div`
  text-align: left;
  color: #ff0000;
  font: normal normal 600 70px/85px Montserrat;
  margin-right: 10px;
`;

export const StatText = styled.div`
max-width: 180px;
display: inline-block;
word-wrap: break-word;
  color: white;
  text-align: left;
  margin-left: 15px;
  font: normal normal 500 22px/28px Montserrat;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 79%;
    text-align: center;
    width: fit-content;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 50px;
  }
`;

export const LottieContainer = styled.div<LottieProps>`
  width: 100%;
  height: 100%;
  top: -15px;
  display: flex;
  position: absolute;

  @media (max-width: ${DESKTOP_1280}px) {
    align-items: center;
    top: ${(props) => props.lottie === "150" ? "-70px " : props.lottie === "193" ? "-70px " : "-35px"};
    left: ${(props) => props.lottie === "150" || props.lottie === "193" ? "25px " : "-33px"};
  }
`;