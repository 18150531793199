import React from "react";
import { graphql } from "gatsby";

import { useIntl } from "gatsby-plugin-intl";

import SEO from "../components/SEO/SEO";
import { Layout } from "../components/Layout";
import { AnimatedTitle } from "../components/commons/AnimatedTitle";

import videoMp4 from "../videos/hero_video_desktop.mp4";
import videoWebm from "../videos/hero_video_desktop.webm";
import mobileVideoMp4 from "../videos/hero_video_mobile.mp4";
import mobileVideoWebm from "../videos/hero_video_mobile.webm";

import videoPosterAvif from "../images/home/background-image.avif";
import videoPosterMobileAvif from "../images/home/back-mobile.avif";
import MouseImg from "../images/home/mouse-icon.svg";

import patternHowWeDoIt from "../images/culture/miscellaneous/how-we-do-it.svg";

import {
  HeroVideoContainer,
  VideoGradient,
  Video,
  HeroTextContainer,
  HeroTitle,
  HeroSubtitle,
  MouseContainer,
} from "../styles/home";

import {
  PageContainer,
  SectionTitle,
  CarrouselContainer,
  StaticMiscellaneous,
  MouseText,
  MouseImage,
} from "../styles/about-us-revamp";

import { PeopleSlider } from "../sections/PeopleSlider";

import { BackgroundWrapper, Container, LargeContainer } from "../styles/commons";

import { Services } from "../sections/AboutUsServices";
import { AwardsCarrousel } from "../sections/Awards";
import { AboutUsContactForm } from "../sections/AboutUsContactForm";
import { WorksSlider } from "../sections/WorksSlider";
import Logos from "../sections/Logos";

export const query = graphql`
  query {
    cms {
      achievementsLogos {
        data {
          attributes {
            LogoTitle
            LogoSubtitle
            LogoSubtitleLine2
            LogoImage {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      }
      works(pagination: { limit: 200 }) {
        data {
          id
          attributes {
            Categories
            Industry
            Thumbnail_Image {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            Project_Title
            Subtitle
            Client_Logo {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            Project_Website
            Priority
            Main_Project_Name
          }
        }
      }
      quotes(pagination: { limit: 200 }, filters: { type: { eq: "Clients" } }) {
        data {
          attributes {
            author
            position
            quote_text
            type
          }
        }
      }
    }
  }
`;

const Home = ({ data }: any) => {
  const intl = useIntl();

  const quotes = data.cms.quotes.data;

  return (
  <Layout initialTextColor="white" landing="home">
    <SEO 
      pageTitle={intl.formatMessage({ id: "seoHomeTitle" })}
      description={intl.formatMessage({ id: "seoHomeDescription" })}
      twitterSite="@wearepsh"
      twitterTitle="Providing Digital Transformation | We Are PSh"
      ogUrl="https://wearepsh.com"
      ogDescription="PSh is a digital agency that creates experiences for brands and customers, merging creativity with technology to create innovative and personalized solutions."
      ogImage={"https://wearepsh.com/images/seo_image.png"} 
    />
    <PageContainer>
    <HeroVideoContainer>
        <MouseContainer>
            <LargeContainer
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
                <MouseImage src={MouseImg} />
                <MouseText>Scroll To Learn</MouseText>
              </LargeContainer>
          </MouseContainer>

          <VideoGradient />
          <Video poster={videoPosterAvif} autoPlay loop muted playsInline>
            <source src={videoWebm} type="video/webm" />
            <source src={videoMp4} type="video/mp4" />
          </Video>
          <Video
            mobile
            poster={videoPosterMobileAvif}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={mobileVideoWebm} type="video/webm" />
            <source src={mobileVideoMp4} type="video/mp4" />
          </Video>

          <HeroTextContainer>
            <LargeContainer>
              <AnimatedTitle
                heightTablet="120px"
                height="160px"
                heightMobile="130px"
                lineDelay={0.5}
              >
                <HeroTitle id="hero">
                  Providing Efficient <br />
                  Digital Solutions
                  <br />
                </HeroTitle>
              </AnimatedTitle>
              <HeroSubtitle>
                We are your all-in-one strategic partner, transforming your
                business with innovative, high-quality services.{" "}
              </HeroSubtitle>
            </LargeContainer>
          </HeroTextContainer>
        </HeroVideoContainer>

        <BackgroundWrapper background="white">
          <Container
            paddingTop="70px"
            paddingTopMobile="40px"
            paddingBottom="55px"
            paddingBottomTablet="55px"
            paddingBottomMobile="55px"
          >
            <AnimatedTitle
              heightTablet="53px"
              height="53px"
              heightMobile="45px"
              lineDelay={0.3}
            >
              <SectionTitle id="scrollSection">What We Do</SectionTitle>
            </AnimatedTitle>
            <Services />
          </Container>
        </BackgroundWrapper>

        <BackgroundWrapper background="#1A1A1D">
          <Container
            paddingBottom="60px"
            paddingTop="60px"
            paddingTopMobile="30px"
            paddingBottomMobile="30px"
          >
            <Logos />
          </Container>
        </BackgroundWrapper>

        <BackgroundWrapper background="#1A1A1D">
          <WorksSlider worksList={data.cms.works.data} />
        </BackgroundWrapper>

        <div style={{ position: "relative", zIndex: 5, background: "#f8f8f8" }}>
          <StaticMiscellaneous
            style={{
              position: "absolute",
              width: "30%",
              bottom: "0",
              right: "-12%",
              transform: "rotate(180deg)",
            }}
            src={patternHowWeDoIt}
            mobileDisplay={"none"}
          />
          <Container
            paddingBottom="80px"
            paddingTop="80px"
            paddingTopMobile="30px"
          >
            <div style={{ position: "relative", zIndex: 5 }}>
              <SectionTitle marginMobile={"15px auto auto auto"}>
                What People Say About Us
              </SectionTitle>
              <div style={{ position: "relative", zIndex: 10 }}>
                <PeopleSlider
                  quotes={quotes}
                  activeCategory="EMPLOYEES"
                  fontColor={"white"}
                />
              </div>
            </div>
          </Container>
        </div>

        <CarrouselContainer
          style={{ position: "relative", background: "white", zIndex: "1" }}
        >
          <StaticMiscellaneous
            style={{
              position: "absolute",
              width: "30%",
              top: "70px",
              right: "-12%",
              transform: "rotate(180deg)",
            }}
            src={patternHowWeDoIt}
          />
          <Container>
            <AnimatedTitle
              heightTablet="30px"
              height="40px"
              heightMobile="45px"
              lineDelay={0.3}
            >
              <SectionTitle smallText center id="awards" color={"#333333"}>
                Our Achievements
              </SectionTitle>
            </AnimatedTitle>
            <AwardsCarrousel items={data.cms.achievementsLogos.data} />
          </Container>
        </CarrouselContainer>
        <AboutUsContactForm></AboutUsContactForm>
      </PageContainer>
    </Layout>
  );
};

export default Home;
