import React, { useEffect, useState } from "react";

import { IconContainer, IconTitle, IconText, Icon, DefaultContainer, HoverContainer } from "./styles";
import { CardsProps } from "./types";

export const Card = ({ title, text, icon }: CardsProps) => {
  return (
    <IconContainer key={title}>
      <IconTitle>{title}</IconTitle>
      <HoverContainer className="hover">
        <IconText>{text}</IconText>
      </HoverContainer>
      <DefaultContainer className="container">
        <Icon className="icon" src={icon} />
      </DefaultContainer>
    </IconContainer>
  );
};
