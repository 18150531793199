/* eslint-disable react/no-array-index-key */
import React from 'react';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { LogosCarrouselMobile, LogosWrapperMobile, SlideTrack, Slide, Image } from './logos.styles';

const Slider = ({ items, sliderKey, sliderDirection }: any) => {
  SwiperCore.use([Autoplay]);
  
  return (
    <LogosCarrouselMobile>
      {/* <Swiper
        spaceBetween={20}
        slidesPerView={2}
        key={sliderKey}
        centerInsufficientSlides
        loop
        autoplay
        breakpoints={{
          740: {
            spaceBetween: 10,
            slidesPerView: 5,
          },
        }}
      >
        {items.map((item: any) => (
          <SwiperSlide
            key={`logo-item-${sliderKey}-${item.Logo_Image.data?.attributes.alternativeText}`}
          >
            <LogoItem image={item.Logo_Image} url={item.Logo_Url} />
          </SwiperSlide>
        ))}
      </Swiper> */}
      <LogosWrapperMobile direction={sliderDirection}>
        <SlideTrack>
          {items.map((item: any) => (
            < Slide
              key={`logo-item-${sliderKey}-${item}`}
            >
              <Image src={item} direction={sliderDirection}/>
            </Slide>
          ))}
          {items.map((item: any) => (
            <Slide
              key={`logo-item-${sliderKey}-${item}`}
            >
              <Image src={item} direction={sliderDirection}/>
            </Slide>
          ))}
        </SlideTrack>
      </LogosWrapperMobile>
    </LogosCarrouselMobile >
  );
};

export default Slider;
