import styled, { css, keyframes } from 'styled-components'
import { MOBILE_460, TABLET_768, MOBILE_380, MOBILE_500, TABLET_992, TABLET_740, TABLET_600 } from "./sizes"

export const HeroVideoContainer = styled.div`
    width: 100%;
    height: 100vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: ${MOBILE_500}px) {
        height: 90vh;
        max-height: 700px;
        min-height: 475px;
    }
`
export const VideoGradient = styled.div`
    background-color: rgba(21,45,57,.7);    
    width: 100%;
    height: 100%;
    position: absolute;
`
export const Video = styled.video<IVideoProps>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: ${({mobile}) => mobile ? "none" : "block"};

    @media(max-width: ${MOBILE_500}px) {
        width: auto;
        max-width: none;
        height: 100vh;
        min-height: 700px;
        display: ${({mobile}) => mobile ? "block" : "none" };
    }
    
`
interface IVideoProps {
    mobile?: boolean;
}

export const HeroTextContainer = styled.div`
    position: absolute;
    width: 100%;
    color: white;
    @media(max-width: ${TABLET_600}px) {
        bottom: 0;
    }
`

export const HeroTitle = styled.h1`
    text-align: left;
    font-family: "Montserrat";
    font-size: 64px;
    line-height: 68px;
    font-variation-settings: 'wght' 700;
    word-break: keep-all;
    @media (max-width: 570px) {
        font-size: 50px;
        line-height: 68px;
;
    }
    @media (max-width: ${TABLET_992}px) {
        margin-top:20px;
        font-size: 35px;
        line-height: 38px;
    }
    @media (max-width: ${TABLET_740}px) {
        margin-top:50px;
        font-size: 35px;
        line-height: 38px;
    }
    @media (max-width: ${MOBILE_500}px) {
        font-size: 34px;
        line-height: 38px;
    }
`

export const SectionTitle = styled.h2<ISectionTitleProps>`
    text-align: left;
    font-family: "Montserrat";
    font-size: 64px;
    line-height: 68px;
    font-variation-settings: 'wght' 700;
    word-break: keep-all;
    
    @media (max-width: ${TABLET_768}px) {
        font-size: 38px;
        line-height: 55px;
        text-align: center;           
    }

    @media (max-width: ${MOBILE_500}px) {
        font-size: ${({fontSizaSmall}) => fontSizaSmall && "30px"};
        line-height: 45px;        
    }

    @media (max-width: 322px) {
        font-size: ${({fontSizaSmall}) => fontSizaSmall && "25px"};       
    }
    
`
interface ISectionTitleProps {
    marginMobile?: string,
    fontSizaSmall?: boolean
}


export const HeroSubtitle = styled.h2`
    text-align: left;
    max-width: 550px;
    font-family: "Montserrat";
    font-size: 24px;
    font-variation-settings: 'wght' 200;
    word-break: keep-all;
    line-height: 32px;
    margin-top: 8px;
    @media (max-width: ${TABLET_740}px) {
        margin-bottom:90px;
    }
`;

export const MouseContainer = styled.div`
    position: absolute;
    bottom: 25px;
    text-align: left;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${TABLET_600}px) {
        display:none     
    }
`;

export const CarrouselContainer = styled.div`
    height: 325px;
    padding: 60px 0 120px;
    width: 100%;
    justify-content: center;
    display: flex;
`

export const CarrouselTitle = styled.div`
    color: #000;
    font-family: 'Montserrat';
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    margin-bottom: 50px;
`


const floating = keyframes`
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   

`

export const MouseImage = styled.img`
    height: 65px;
    margin: auto;
    animation: 1.5s ${floating} ease-in-out infinite ;
`
