import React, { useEffect, useRef, useState } from "react";
import bodymovin, { AnimationItem } from "lottie-web";

import heroAnimation from "./assets/hero-main.json";
import introAnimationData from "./assets/hero-auto-play.json";
import centerAnimationData from "./assets/hero-circle.json";
import tooltipImage from "./assets/tooltip.svg";
import {
  AnimationContainer,
  Container,
  HeroContent,
  HeroSubtitle,
  HeroTitle,
} from "./styles";
import { AnimatedTitle } from "../../components/commons/AnimatedTitle";

export const HomepageHero = () => {
  const containerRef = useRef(null);
  const introRef = useRef(null);
  const centerLoopRef = useRef(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);
  const [introAnimation, setIntroAnimation] = useState<AnimationItem | null>(null);
  const [centerAnimation, setCenterAnimation] = useState<AnimationItem | null>(null);
  const [currentScene, setCurrentScene] = useState<string>("initial");
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  const currentSceneRef = useRef<string>();
  currentSceneRef.current = currentScene;

  // apply animations 



  const applyFrameListener = (currentTime: any, elementId: string) => {
    const centerFinishTime=  28;
    const backFinishTime = 31;
    if (currentTime == centerFinishTime && currentSceneRef.current === `word-${elementId}`) {
      setCurrentScene(`center-${elementId}`);
      let centerButton = document.querySelector<HTMLElement>("#s01_obj01_in");
      introAnimation.stop()
      centerButton.style.cursor = "pointer";
      centerLoopRef.current.style.display = "block";
      centerAnimation.play()
      centerButton.addEventListener("click", () =>
        handleElementClick("centerButton")
      );
    }
    if (currentTime == backFinishTime && currentSceneRef.current === `back-${elementId}`) {
      setCurrentScene("initial");
      animation.setSegment(0, animation.totalFrames);
      animation.goToAndStop(0);
      animation.goToAndStop(0, true);
      introAnimation.play()
    }
  }

  const applyClickAnimation = (elementId: string, centerClickStartFrame: number, centerClickEndFrame: number, initialClickStartFrame: number, initialClickEndFrame: number) => {
    if (currentSceneRef.current === `center-${elementId}`) {
      //center click
      setCurrentScene(`back-${elementId}`)
      centerLoopRef.current.style.display = "none";
      animation.playSegments([centerClickStartFrame, centerClickEndFrame], true);
    }
    if (
      currentSceneRef.current === "initial" ||
      currentSceneRef.current ===  `hover-${elementId}`
    ) {
      //initial click
      introAnimation.stop()
      animation.playSegments([initialClickStartFrame, initialClickEndFrame], true);
      setCurrentScene(`word-${elementId}`);
    }
  }

  const applyHoverAnimation = (elementId: string, centerHoverStartFrame: number, centerHoverEndFrame: number, initialHoverStartFrame: number, initialHoverEndFrame: number) => {
    //center hover
    if (currentSceneRef.current === `center-${elementId}`) {
      animation.playSegments([centerHoverStartFrame, centerHoverEndFrame], true);
    }
    //initial hover
    else if (animation.currentFrame === 0) {
      introAnimation.stop()
      animation.playSegments([initialHoverStartFrame, initialHoverEndFrame], true);
      setCurrentScene(`hover-${elementId}`);
    }
}

const applyLeaveAnimation = (elementId: string, centerHoverStartFrame: number, centerHoverEndFrame: number, initialHoverStartFrame: number, initialHoverEndFrame: number) => {
  if (currentSceneRef.current === `hover-${elementId}`) {
    //initial hover leave
    animation.setDirection(-1);
    animation.playSegments([initialHoverEndFrame, initialHoverStartFrame], true);
    introAnimation.play()
    setCurrentScene("initial");
  } else if (currentSceneRef.current === `center-${elementId}`) {
    //center hover leave
    animation.setDirection(-1)
    animation.playSegments([centerHoverEndFrame, centerHoverStartFrame], true);
    animation.goToAndStop(48)
  }
}

type InteractiveElement = {
  element: string;
  centerClickStartFrame: number;
  centerClickEndFrame: number;
  initialClickStartFrame: number;
  initialClickEndFrame: number;
  centerHoverStartFrame: number;
  centerHoverEndFrame: number;
  initialHoverStartFrame: number;
  initialHoverEndFrame: number;
};

type InteractiveElementsType = Record<string, InteractiveElement>;

const InteractiveElements: InteractiveElementsType = {
  'Seamless': {
    element: '#s01_text01_in',
    //clicks
    centerClickStartFrame: 55,
    centerClickEndFrame: 87,
    initialClickStartFrame: 19,
    initialClickEndFrame: 48,
    //hovers:
    centerHoverStartFrame: 51,
    centerHoverEndFrame: 54,
    initialHoverStartFrame: 8,
    initialHoverEndFrame: 16,
  },
  'Growth': {
    element: '#s01_text02_in',
    //clicks
    centerClickStartFrame: 136,
    centerClickEndFrame: 168,
    initialClickStartFrame: 100,
    initialClickEndFrame: 129,
    //hovers:
    centerHoverStartFrame: 132,
    centerHoverEndFrame: 135,
    initialHoverStartFrame: 89,
    initialHoverEndFrame: 96,
  },
  'Tireless': {
    element: '#s01_text03_in',
    //clicks
    centerClickStartFrame: 217,
    centerClickEndFrame: 249,
    initialClickStartFrame: 181,
    initialClickEndFrame: 210,
    //hovers:
    centerHoverStartFrame: 213,
    centerHoverEndFrame: 216,
    initialHoverStartFrame: 170,
    initialHoverEndFrame: 177,
  },
  'Collaboration': {
    element: '#s01_text04_in ',
    //clicks
    centerClickStartFrame: 298,
    centerClickEndFrame: 330,
    initialClickStartFrame: 262,
    initialClickEndFrame: 291,
    //hovers:
    centerHoverStartFrame: 294,
    centerHoverEndFrame: 297,
    initialHoverStartFrame: 251,
    initialHoverEndFrame: 258,
  },
}

// handle events  
  const handleEnterFrame = (e: any) => {
    if (e?.currentTime) {
      const currentElement = currentSceneRef.current.split('-')[1]
      applyFrameListener(e.currentTime, currentElement)
    }
  };

  const handleElementClick = (elementId: string, reverse?: true) => {
    if (animation) {
      if(elementId in InteractiveElements){
        const element = InteractiveElements[elementId]
        applyClickAnimation(
          elementId,
          element.centerClickStartFrame,
          element.centerClickEndFrame,
          element.initialClickStartFrame,
          element.initialClickEndFrame
        );
      }
    }
  };

  const handleElementHover = (elementId: string, reverse?: true) => {
    if (animation) {

      if(elementId in InteractiveElements){
        const element = InteractiveElements[elementId]
        applyHoverAnimation(
          elementId,
          element.centerHoverStartFrame,
          element.centerHoverEndFrame,
          element.initialHoverStartFrame,
          element.initialHoverEndFrame
        );
      }

      if(elementId === 'centerButton' && currentSceneRef.current === 'initial'){
        setTooltipVisible(true)
      }
    }
    
    
  };

  const handleElementLeave = (elementId: string) => {
    if (animation) {
      
 
      if(elementId in InteractiveElements){
        const element = InteractiveElements[elementId]
        applyLeaveAnimation(
          elementId,
          element.centerHoverStartFrame,
          element.centerHoverEndFrame,
          element.initialHoverStartFrame,
          element.initialHoverEndFrame
        );
      }
      if(elementId === 'centerButton'){
        setTooltipVisible(false)
      }

    }
  };


  // add listeners 
  const addEventListeners = () => {
    addListener("#s01_obj02_in", "centerButton", true)
    for (const key of Object.keys(InteractiveElements)) {
      const element = InteractiveElements[key];
      addListener(element.element, key)
    }
  };

  const addListener = (elementQuerySelector: string, elementId: string, avoidPointer?: boolean) => {
    let element = document.querySelector<HTMLElement>(elementQuerySelector);
    if(!avoidPointer){
      element.style.cursor = "pointer";
    }
    element.addEventListener("mouseenter", () =>
      handleElementHover(elementId)
    );
    element.addEventListener("mouseleave", () =>
      handleElementLeave(elementId)
    );
    element.addEventListener("click", () =>
      handleElementClick(elementId)
    );
  }


  // initialize animations
  useEffect(() => {
    const introAnim = bodymovin.loadAnimation({
      container: introRef.current,
      renderer: "svg",
      autoplay: true,
      loop: true,

      animationData: introAnimationData,
    });

    const centerLoopAnim = bodymovin.loadAnimation({
      container: centerLoopRef.current,
      renderer: "svg",
      autoplay: false,
      loop: true,

      animationData: centerAnimationData,
    });

    const anim = bodymovin.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: false,
      loop: false,

      animationData: heroAnimation,
    });

    setIntroAnimation(introAnim);
    setCenterAnimation(centerLoopAnim);
    setAnimation(anim);
  }, []);

  useEffect(() => {
    if (currentScene === "initial") {
      let centerButton = document.querySelector<HTMLElement>("#s01_obj01_in");

      centerButton.style.cursor = "none";
    }
  }, [currentScene]);

  useEffect(() => {
    if (animation) {
      animation.addEventListener("DOMLoaded", addEventListeners);
      animation.addEventListener("enterFrame", handleEnterFrame);
    }

    return () => {
      window.removeEventListener("enterFrame", handleEnterFrame);
    };
  }, [animation]);

  return (
    <Container id="herocontainer">
      <HeroContent>
        <AnimatedTitle height="220px" heightMobile="120px" lineDelay={0.5}>
          <HeroTitle id="hero">
            Providing <br />
            Digital
            <br />
            Transformation
          </HeroTitle>
        </AnimatedTitle>
        <HeroSubtitle>
          The partner your business needs for digital quality solutions
        </HeroSubtitle>
      </HeroContent>
      <AnimationContainer ref={introRef}></AnimationContainer>
      <img src={tooltipImage} style={{transition: 'all 0.3s', opacity: tooltipVisible ? '1' : '0', visibility: tooltipVisible ? 'visible' : 'hidden', position: 'absolute', zIndex: '191', width: '10%', top: '42%', right: '12%'}}/>
      <AnimationContainer
        style={{
          display: 'none',
          zIndex: "189",
        }}
        ref={centerLoopRef}>
      </AnimationContainer>
      <AnimationContainer
        style={{
          zIndex: "190",
        }}
        ref={containerRef}
      ></AnimationContainer>
    </Container>
  );
};
