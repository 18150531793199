import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import coflex_banner from './assets/coflex.jpg'
import jurassic_banner from './assets/jurassic.jpg'
import nearpod_banner from './assets/nearpod.jpg'
import pathful_banner from './assets/pathful.jpg'
import wellnet_banner from './assets/wellnet.jpg'
import { DESKTOP_1920, TABLET_768 } from '../../styles/sizes';
import { Wrapper } from './styles';

const Container = styled.ul`
  width: 100%;
  height: 85vh;
  max-height: 900px;
  min-height: 600px;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  list-style-type: none;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    height: 75vh;
  }

  @media only screen and (max-width: 768px) {
    height: 900px;
  }
`;

const ListItem = styled.div<{xPosition?: string, yPositionMobile?: string, yPosition: string}>`
  min-height: 50px;
  min-width: 260px;
  flex: 1;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  transition: all 0.35s ease;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position-x: ${(props) => props.xPosition};
  background-position-y: ${(props) => props.yPosition};

  &:before {
    content: "";
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 15, 15, 0.75);
  }

  ${props =>
    props.active &&
    css`
      min-height:290px;
      flex: 6;
      cursor: default;

      &:before {
        background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #111111 100%);
      }
    `}


  @media (max-width: ${DESKTOP_1920}px) { 
    min-width: unset;
  }

  @media only screen and (max-width: 1300px) {
    background-position-y: ${(props) => props.yPositionMobile};
  }

  @media only screen and (max-width: 768px) {
    height: 100%;
    ${props =>
    !props.active &&
    css`
      min-height: 90px;
    `}
  }
`;

const Heading = styled.h3<{active: boolean}>`
  font-weight: bold;
  white-space: nowrap;
  position: absolute;
  z-index: 30;
  font-size: 38px;
  opacity: 1;
  top: 50%;
  left: 50%;
  transition: top 0.35s, opacity 0.15s;
  transform-origin: 0 0;
  font-size: 24px;
  transform: translate(-50%, -50%) rotate(0deg);
  color: white;
  width: 100%;
  margin-left: 15px;

  @media only screen and (min-width: 768px) {
    margin-left: 0;
    font-size: 44px;
    margin-top: -30px;
    top: 100%;
    left: 50%;
    font-size: 32px;
    transform: translate(-20px, -50%) rotate(-90deg);
  }

  ${(props) => props.active && `
    opacity: 0;
    top: 200%;
  `}
`;

const SectionContent = styled.div<{active: boolean}>`
  width: 100%;
  position: relative;
  z-index: 30;
  opacity: 0;
  align-self: flex-end;
  transition: all 0.35s 0.1s ease-out;
  box-sizing: border-box;

  .inner {
    padding: 20px;
    position: absolute;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    align-items: flex-end;
    left: 260px;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.25s ease-out;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) {
      grid-auto-flow: column;
      grid-template-columns: 460px 200px;
      grid-column-gap: 40px;
      padding: 40px 40px 40px 0;
    }

    @media (max-width: ${DESKTOP_1920}px) { 
      padding: 40px;
      left: 0;
    }

    @media only screen and (max-width: 900px) {
      grid-template-columns: 80% 20%;
    }
    @media only screen and (max-width: 768px) {
      padding: 40px 15px;
      grid-template-columns: 56% 24%;
    }
    @media only screen and (max-width: 414px) {
      grid-template-columns: 100% 0;
    }
  }
  ${(props) => props.active && `
  opacity: 1;
  .inner {
    opacity: 1;
  }
  `}

`;

const Bio = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content;
  color: white;
`;

const ProjectTitle = styled.h2`
  font: bold 30px/36px "Montserrat-Bold";
  white-space: nowrap;

  @media only screen and (min-width: 1200px) {
    font: bold 70px/76px "Montserrat-Bold";
  }
`;

const ProjectDescription = styled.p`
font-size: 18px;
line-height: 24px;
margin-bottom: 25px;
margin-top: 5px;

@media only screen and (min-width: 1200px) {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 35px;
  margin-top: 15px;
}
`;


const ProjectButton = styled.a`
  max-width: 250px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: red;
  outline: none;
  border: none;
  margin: none;
  padding: 18px 15px;
  border-radius: 10px;
  font: bold 20px/26px "Montserrat-Bold";
  text-decoration: none;
  cursor: pointer;
  transition: background 0.2s;
  margin-bottom: 15px;

  &:hover {
    background: #7971FA;
  }
  &:focus {
    outline: none
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const SeeAllButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  color: white;
  background: #1A1A1D;
  outline: none;
  border: none;
  margin: none;
  padding: 25px;
  font: bold 30px/36px "Montserrat-Bold";
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #7971FA;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 900px) {
    font: bold 20px/26px "Montserrat-Bold";
    width: auto;
  }
`;

export const WorksSlider = () => {
  const [active, setActive] = useState(0);


  const projects = [
    {
      name: "Pathful",
      description: "Empowering future careers with interactive exploration tools and resources for students.",
      portfolio: {
        projectUrl: "/work/pathful",
      },
      backgroundUrl: pathful_banner,
      xPosition: '85%',
      yPosition: '30%',
      xPositionClosed: '81%',
      yPositionMobile: '30%',

    },
    {
      name: "Nearpod",
      description: "Transforming teaching with engaging, interactive, and insightful educational experiences.",
      portfolio: {
        projectUrl: "/work/nearpod",
      },
      backgroundUrl: nearpod_banner,
      xPosition: '85%',
      yPosition: '25%',
      xPositionClosed: '80%',
      yPositionMobile: '50%',
    },
    {
      name: "WellNet",
      description: "Optimizing healthcare with strategic solutions for effective cost control and plan management.",
      portfolio: {
        projectUrl: "/work/wellnet",
      },
      backgroundUrl: wellnet_banner,
      xPosition: '85%',
      xPositionClosed: '70%',
      yPositionMobile: '10%',

    },    {
      name: "Coflex",
      description: "Elevating home functionality with stylish, efficient fixtures and fittings for modern living.",
      portfolio: {
        projectUrl: "/work/coflex",
      },
      backgroundUrl: coflex_banner,
      xPosition: '68%',
      xPositionClosed: '61%',
      yPositionMobile: '56%',

    },
    {
      name: "Jurassic World",
      description: "Blending adventure and endurance, Spartan Trail's Jurassic World offers thrilling experiences for young explorers.",
      portfolio: {
        projectUrl: "/work/jurassic_world",
      },
      backgroundUrl: jurassic_banner,
      xPosition: '85%',
      xPositionClosed: '85%',
      yPositionMobile: '10%',
    },
  ];

  // const nextSlide = () => {
  //   if (active < projects.length - 1) {
  //     setActive(active + 1);
  //   }
  // };

  // const prevSlide = () => {
  //   if (active > 0) {
  //     setActive(active - 1);
  //   }
  // };

  return (
      <Wrapper>
        <Container>
          {projects.map((project, i) => (
            <ListItem
              id={`project-${project.name}`}
              key={i}
              xPosition={(active === i) ? project.xPosition : (project. xPositionClosed || project.xPosition)}
              yPositionMobile={project.yPositionMobile}
              yPosition={project.yPosition || ''}
              style={{backgroundImage: `url(${project.backgroundUrl})`}}
              active={active === i}
              onClick={() => setActive(i)}
            >
              <Heading active={active === i}>{project.name}</Heading>
              <SectionContent active={active === i}>
                <div className="inner">
                  <Bio>
                    <ProjectTitle>{project.name}</ProjectTitle>
                    <ProjectDescription>{project.description}</ProjectDescription>
                    {active === i &&
                    <ProjectButton target='_blank' href={`${project.portfolio.projectUrl}`}>SEE MORE</ProjectButton>
                    }
                  </Bio>
                </div>
              </SectionContent>
            </ListItem>
          ))}
        </Container>
        <SeeAllButton href='/work'>SEE ALL PROJECTS</SeeAllButton>
    </Wrapper>
  );
};