import styled, { keyframes } from 'styled-components'
import * as commons from '../../styles/commons';
import { TABLET_768, MOBILE_460 } from '../../styles/sizes';

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`

export const WorksSection = styled.div`
  padding: 50px 0;
  flex-direction: column;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  grid-template-rows: repeat(7, 145px);
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto repeat(4, calc(75px + 27vw));
    padding: 50px 0;
  }
`

export const Button = styled.a`
  border: 2px solid #FFFFFF;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 20px;
  font-variation-settings: 'wght' 600;
  text-decoration: none;
  transition: 0.5s ease;

  @media (max-width: ${TABLET_768}px) {
    height: fit-content;
    justify-content: space-between;
  }
  &:hover, :active {
    color: red;
    background-color: white;
    transition: 0.5s ease;
    & div {
      filter: none;  
    }
  }
`

export const LottieEdit = styled.div`
  filter: brightness(0) invert(1);
  &:hover,
  :active {
    filter: none;  
  }
`

export const OpacityLayer = styled.div<ILayerProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  top: 0;
  left: 0;
  opacity: 0;
  animation-name: ${fade};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  z-index: 20;
  ${ props => props.visible && 'color: red'}
`

interface ILayerProps {
  visible?: boolean
}

export const Wave = styled.img`
  z-index: 20;
  position: absolute;
  width: 100vw;
  height: auto;
  top: -12.19vw;
  left: 0;
  height: auto;
  opacity: 1;
`

export const Title = styled.h3`
  color: white;
  font-variation-settings: 'wght' 700;
  font-size: 55px;
  text-align: left;

  @media (max-width: ${TABLET_768}px) { 
    font-size: calc(22px + 8vw);
  }
  @media (max-width: ${MOBILE_460}px) { 
    font-size: 38px;
    line-height: 42px;
  }
`;

export const Background = styled.div`
  background: transparent linear-gradient(180deg, #FF0064 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
`

export const Container = styled(commons.Container)``;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 2560px;
  margin: 0 auto;
`;