export const DESKTOP_1920 = 1920
export const DESKTOP_1440 = 1440
export const DESKTOP_1366 = 1366
export const DESKTOP_1280 = 1280
export const DESKTOP_1200 = 1200
export const DESKTOP_1100 = 1100
export const DESKTOP_1024 = 1024

export const TABLET_1023 = 1023
export const TABLET_992 = 992
export const TABLET_800 = 800
export const TABLET_768 = 768
export const TABLET_740 = 740
export const TABLET_600 = 600
export const TABLET_570 = 570

export const MOBILE_500 = 500
export const MOBILE_460 = 460
export const MOBILE_380 = 380
export const MOBILE_320 = 320
