/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-extra-boolean-cast */
import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const CMSParseImage = (imageFile: any) => {
  if (
    !!(
      imageFile?.attributes?.urlSharp?.childImageSharp ||
      imageFile?.data?.attributes?.urlSharp?.childImageSharp
    )
  ) {
    const parsedGastbyImage = getImage(
      imageFile?.attributes?.urlSharp?.childImageSharp ||
        imageFile?.data?.attributes?.urlSharp?.childImageSharp,
    );
    return (
      <GatsbyImage
        style={{ width: '100%', height: 'fit-content' }}
        image={parsedGastbyImage}
        alt=""
      />
    );
  }
  const urlImage = imageFile?.attributes?.url || imageFile?.data?.attributes?.url;
  return <img style={{ width: '100%' }} src={urlImage} alt="" />;
};
