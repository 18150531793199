import styled, { keyframes } from 'styled-components';
import { DESKTOP_1440, DESKTOP_1024, TABLET_1023, TABLET_800, TABLET_740, MOBILE_460, MOBILE_380, MOBILE_320 } from "../../styles/sizes";
import { PRIMARY_COLOR } from "../../styles/colors";

export const Desktop = styled.div`
    @media (max-width: 1023px) {
        display: none;
    }
`
export const HeroTextContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 180px 0 0px;
    color: black;
    @media (max-width: ${DESKTOP_1024}px) {
        padding: 180px 0 50px;
    }
    @media (max-width: ${TABLET_740}px) {
        width: 90%;
        padding: 100px 0 0;
        margin: 0 auto;
    }
`
export const Title = styled.h1`
    font-size: 64px;
    line-height: 68px;
    text-align: center;
    font-family: "Montserrat";
    font-variation-settings: 'wght' 700;
    word-break: keep-all;
    @media (max-width: ${TABLET_740}px) {
        font-size: 8vw;
        line-height: 75px;
        text-align: left;
        color: #223238;
    }
    @media (max-width: ${MOBILE_460}px) {
        font-size: 10vw;
        line-height: 40px;
    }
`
export const FilterSection = styled.div`
    width: fit-content;
    height: fit-content;
    margin: 50px auto 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
export const FilterList = styled.ul`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`
export const FilterItem = styled.li<IFilterItem>`
    width: fit-content;
    height: 100%;
    font-weight: 300;
    font-family: 'Montserrat-Light';
    margin: 10px 12px;
    padding: 0;
    color: black;
    word-wrap: normal;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    ${(props) => props.isActive && `font-weight: bold; font-family: 'Montserrat-Bold';`};
    :active{
        font-weight: bold;
        font-family: 'Montserrat-Bold';
    }
    @media (max-width: 1023px) {
        font-size: 1.8vw;
        margin: 10px 20px;
    }
`
export const FilterBar = styled.div`
    width: 100%;
    height: 1px;
    background: #d9dee1;
    position: relative;
`
const move = (from : string, to : string) => keyframes`
    from { left: ${from} };
    to { left: ${to} };
`
export const FilterIndicator = styled.div<IFilterIndicator>`
    width: ${props => {
        const category = props.categoryWidth;
        if (category === 'Design & Creative') {
            return '20%';
        } else if (category === 'Development & Engineering') {
            return '28%';
        } else if (category === 'Growth Marketing') {
            return '22%';
        } else if (category === 'Content Production') {
            return '22.5%';
        } else return '5.5%';
    }};
    height: 5px;
    background: red;
    position: absolute;
    top: -2px;
    animation:  ${(props) => move(props.from,props.to)} 0.6s forwards;
    transition: transform 0.6s;
    transition: width 0.6s ease-in-out;
`
export const FilterSectionMobile = styled.div`
    width: 100%;
    height: auto;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;
    @media (max-width: ${TABLET_1023}px) {
        display: flex;
    }
`
export const FilterItemMobile = styled.div<IFilterItem>`
    width: 90%;
    height: 100%;
    margin: 10px 20px;
    padding: 25px 20px;
    color: black;
    word-wrap: normal;
    text-transform: uppercase;
    text-align: left;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-variation-settings: 'wght' 600;
    text-decoration: none;
    transition: 0.5s ease;
    z-index: 2;
    ${(props) => props.isActive ?
        `border: 2px solid red;
        color: red;
        & img {
            filter: none;
            transform: rotate(0deg);
        }`
        : 
        `border: 2px solid #B8BCBF; 
        color: #B8BCBF;
        & img {
            filter: brightness(1) grayscale(1) contrast(0);
            transform: rotate(180deg); 
        }`
    };
    @media (max-width: ${MOBILE_380}px) {
        font-size: 16px;
        padding: 25px 32px 25px 20px;
    }
`
export const ArrowIcon = styled.img`
    width: 15px;
    height: 10px;
    cursor: pointer;
    color: red;
    transform: rotate(180deg);
    filter: brightness(1) grayscale(1) contrast(0);
    position: absolute;
    right: 8%;
    transition: 0.5s ease;
    @media (max-width: ${MOBILE_460}px) {
        right: 11%;
    }
`
interface IFilterIndicator {
    from: string,
    to: string,
    categoryWidth?: string,
}
interface IFilterItem {
    isActive?: boolean,
    isSeeMore?: boolean,
    activeCategory?: string,
}
export const WorksSection = styled.div`
    width: 80%;
    margin: 0 auto 80px;
    padding: 0;
    flex-direction: column;
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    grid-auto-rows: 1fr;
    grid-gap: 30px;
    @media (max-width: ${DESKTOP_1440}px) {
        width: 90%;
        grid-gap: 30px;
    }
    @media (max-width: ${TABLET_1023}px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

export const CenteredDiv = styled.div<ISeeMoreProps>`
    width: 90%;
    ${(props) => props.isActive ? `display: flex;` : `display: none;`};
    flex-direction: column;
    align-items: center;
    z-index: 1;
    margin: 20px 0;
    ${(props) => props.fiveMoreProjects 
        ? `position: relative;
            top: 60px;` 
        : `position: static;`
    };
    @media (max-width: ${TABLET_800}px) {
        position: static;
        margin: 30px 0;
    }
`
export const WorksSectionMobile = styled.div<IFilterItem>`
    width: 100%;
    height: auto;
    padding: 0;
    flex-direction: column;
    grid-auto-rows: 1fr; 
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    ${(props) => props.isActive ? `display: grid;` : `display: none;`};
    @media (max-width: ${TABLET_800}px) {
        grid-template-columns: 1fr;
        margin: ${(props) => props.isSeeMore ? `20px 0 0;` : `0`};
    }
`
export const LoadingContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
export const SeeMore = styled.div<ISeeMoreProps>`
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    color: red;
    font-size: 20px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    &:active, &:focus {
        color: #b60101;
    }
    @media (max-width: ${TABLET_1023}px) {
        grid-column-end: span 2;        
        ${(props) => props.fiveMoreProjects ? 
            `height: fit-content;
            position: relative;
            top: -110px;`
            : 
            `height: 150px;
            align-items: center;`
        };
    }
    @media (max-width: ${TABLET_800}px) {
        grid-column-end: span 1;        
        ${(props) => props.fiveMoreProjects ? 
            `position: static;`
            : 
            `height: 20px;
            margin-top: 10px;
            align-items: center;`
        };
    }
`
interface ISeeMoreProps {
    fiveMoreProjects?: boolean;
    isActive?: boolean;
}
