import styled from 'styled-components';
import { OnViewProps, AnimationOnView } from './onViewTrigger.types';

export const OnView = styled.div<OnViewProps>`
  display: flex;
  @media (max-width: 768px) {
    ${({ animationOnView, inView }) =>
      animationOnView === AnimationOnView.mobileAppear &&
      `transition: opacity 0.5s ease-in;${inView ? 'opacity: 1;' : 'opacity: 0;'}`}
  }
`;
