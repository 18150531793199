/* eslint-disable react/no-array-index-key */
import React from 'react';
import { LogosWrapper, LogosTitle, MobileContainer, Filter } from './logos.styles';
import { LogosProps } from './logos.types';
import Slider from './Slider';
import SliderMobile from './SliderMobile';
import { Markdown } from 'react-showdown';

import bn from "../../images/clients-new/book_nook.svg"
import charthop from "../../images/clients-new/charthop_gris.svg"
import abinbev from "../../images/clients-new/client_logo_abinbev.svg"
import cnt from "../../images/clients-new/client_logo_cnt.svg"
import globant from "../../images/clients-new/client_logo_globant.svg"
import nearpod from "../../images/clients-new/client_logo_nearpod.svg"
import sanofi from "../../images/clients-new/client_logo_sanofi.svg"
import stanford from "../../images/clients-new/client_logo_stanford.svg"
import unilever from "../../images/clients-new/client_logo_unilever.svg"
import cocacola from "../../images/clients-new/COCA_COLA.svg"
import digital_house from "../../images/clients-new/DIGITAL_HOUSE.svg"
import directv from "../../images/clients-new/DIRECTV.svg"
import kiddom from "../../images/clients-new/client_logo_kidom-w.svg"
import lantos from "../../images/clients-new/LANTOS.svg"
import marvel from "../../images/clients-new/client_logo_marvel-blanco.svg"
import motive from "../../images/clients-new/MOTIVE.svg"
import spartan from "../../images/clients-new/spartan_gris.svg"

const Logos = () => {

const imageList1 = [
  bn,
  charthop,
  abinbev,
  cnt,
  globant,
  nearpod,
  sanofi,
  stanford,
  unilever,
]

const imageList2 = [
  cocacola,
  digital_house,
  directv,
  kiddom,
  lantos,
  marvel,
  motive,
  spartan,
]
  return (
    <LogosWrapper>
        <LogosTitle>
          We are backed by
        </LogosTitle>
        <MobileContainer>
          <Filter />
          <Filter id="invert" invert />
          <SliderMobile sliderDirection items={imageList1} sliderKey={1} />
          <SliderMobile sliderDirection={false} items={imageList2} sliderKey={1} />
        </MobileContainer>
    </LogosWrapper>
  );
};

export default Logos;
