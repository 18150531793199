import React, { useEffect, useState } from "react";

import ArrowIcon from "./assets/arrow.svg";
import RedArrowIcon from "./assets/red-arrow.svg";

import { InfoCard, Arrow, CardTitle, CardText, RedArrow, Icon } from "./styles";
import { VisionCardsProps } from "./types";

export const Card = ({
  title,
  text,
  onClick,
  cardNumber,
  currentCard,
  image,
}: VisionCardsProps) => {
  const clickCard = () => {
    if (cardNumber === currentCard) {
      onClick("");
    } else {
      onClick(cardNumber);
    }
  };

  return (
    <InfoCard openCard={currentCard === cardNumber} onClick={clickCard}>
      {currentCard === cardNumber ? (
        <>
          <RedArrow src={RedArrowIcon} />
          <Icon src={image} />
        </>
      ) : (
        <Arrow src={ArrowIcon} />
      )}
      <CardTitle openCard={currentCard === cardNumber}>{title}</CardTitle>
      {currentCard === cardNumber && <CardText>{text}</CardText>}
    </InfoCard>
  );
};
