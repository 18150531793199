import React from 'react'

import { graphql } from "gatsby";

import { Layout } from '../../components/Layout'
import SEO from '../../components/SEO/SEO'

import { MarkdownParser } from '../../components/Markdown';
import { LegalContainer } from '../../styles/commons';

export const query = graphql`
query ($id: ID!) {
  cms {
    legalPage(id: $id) {
      data {
        attributes {
          paragraph
        }
        id
      }
    }
  }
}
`

const Legal: React.FC<any> = ( {data} ) => {
  const paragraph = data?.cms?.legalPage?.data?.attributes?.paragraph
  return (
    <Layout privacyPolicy initialTextColor="white" landing="legal">
      <LegalContainer>
        <MarkdownParser data={paragraph} />
      </LegalContainer>
    </Layout>
  )
}



export default Legal;