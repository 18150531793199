import React, { useEffect, useRef, useState } from 'react';
import bodymovin, { AnimationItem } from 'lottie-web';

import { LottieContainer } from './styles';

export const LottieScrollPlayer: React.FC<IProps> = (props: IProps) => {
  const {
    animationSpeed,
    animationDelay,
    animationData,
    animationHeight,
    animationWidth,
    key,
    onComplete,
    onStart,
    forceFinish,
    assetsPath,
  } = props;
  const containerRef = useRef<HTMLInputElement>(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);
  const [frame, setFrame] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState<boolean>(false);

  const getContainerDistanceToViewport = () => {
    if (containerRef && containerRef.current) {
      const { top, height } = containerRef.current.getBoundingClientRect();
      // Calculate current view percentage
      const current = window.innerHeight - top;
      const max = window.innerHeight + height - 120;
      return current / max;
    }
    return -1;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // define lottie animation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (containerRef && containerRef.current && !isComplete && !forceFinish) {
      const anim = bodymovin.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        autoplay: false,
        loop: false,
        animationData,
        assetsPath,
      });
      setAnimation(anim);

      let myframe = 0;

      const onScroll = () => {
        if (!isComplete && containerRef && containerRef.current && !forceFinish) {
          const position = getContainerDistanceToViewport();
          const maxFrames = anim.totalFrames;

          const newFrame = maxFrames * (position - (animationDelay || 0)) * (animationSpeed || 1);
          if (newFrame > myframe) {
            myframe = newFrame;
            setFrame(newFrame);
          }
        }
      };

      document.addEventListener('scroll', onScroll);
    }
  }, [animationSpeed, isComplete, forceFinish, animationData, animationDelay, assetsPath]);

  useEffect(() => {
    if (forceFinish) {
      animation?.goToAndStop(animation.totalFrames - 1, true);
    }
  }, [forceFinish, animation]);

  useEffect(() => {
    if (isComplete && onComplete) {
      onComplete();
    }
  }, [isComplete, onComplete]);

  useEffect(() => {
    if (hasStarted && onStart && !isComplete) {
      onStart();
    }
  }, [hasStarted, onStart, isComplete]);

  useEffect(() => {
    if (animation) {
      const maxFrames = animation.totalFrames;
      if (frame > 0) {
        setHasStarted(true);
      }
      if (frame < maxFrames && !forceFinish) {
        animation.goToAndStop(frame, true);
      } else {
        setIsComplete(true);
      }
    }
  }, [frame, animation, isComplete, forceFinish]);

  return (
    <LottieContainer
      height={animationHeight || ''}
      width={animationWidth || ''}
      className="lottie--container"
      ref={containerRef}
      key={key || undefined}
    />
  );
};

interface IProps {
  animationSpeed?: number;
  forceFinish?: boolean;
  animationDelay?: number;
  animationData: any;
  animationWidth?: string;
  animationHeight?: string;
  key?: string;
  onComplete?: () => void;
  onStart?: () => void;
  assetsPath?: string;
}
const defaultProps = {
  forceFinish: false,
  animationSpeed: 1,
  animationDelay: 0,
  animationWidth: 'auto',
  animationHeight: 'auto',
  key: '',
  onComplete: () => {},
  onStart: () => {},
  assetsPath: '',
};
LottieScrollPlayer.defaultProps = defaultProps;
