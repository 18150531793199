import React, { useEffect, useRef, useState } from "react";

import HowWeDoItLottie from "./assets/How_We_Do_It_Panel_v01.json";
import HowWeDoItMobile from "./assets/mobile.svg";

import pattern from "./assets/pattern.svg";
import {
  Container,
  Title,
  LottieInfoContainer,
  Pattern,
  TextLottieContainer,
  TextContainer,
  MobileLottie,
  LottieContainer,
} from "./styles";
import { CultureContainer } from "../../styles/commons";
import { LottieScrollPlayer } from "../../components/commons/LottiePlayer/LottieScrollPlayer";
import OnViewTrigger from "../../components/OnViewTrigger/Index";
import { StaticMiscellaneous } from "../../styles/about-us-revamp";

export const HowWeDoIt = () => {
  const [forceFinish, setForceFinish] = useState<boolean>(false);

  const options = {
    animationDelay: 0.2,
    animationSpeed: 1.3,
  };

  return (
    <div style={{ position: "relative" }}>
        <CultureContainer paddingTop="0">
          <TextLottieContainer>
            <TextContainer>
              <Title>How We Do It</Title>
            </TextContainer>
            <LottieInfoContainer>
              <LottieContainer>
                <LottieScrollPlayer
                  animationDelay={options.animationDelay}
                  animationSpeed={options.animationSpeed}
                  animationData={HowWeDoItLottie}
                  forceFinish={forceFinish}
                />
              </LottieContainer>
              <MobileLottie src={HowWeDoItMobile} />
            </LottieInfoContainer>
          </TextLottieContainer>
        </CultureContainer>
      <OnViewTrigger
        onView={() => setForceFinish(true)}
        TriggerPosition={0.5}
        maxTriggerPosition={0.6}
        finishOnView={() => setForceFinish(false)}
      />
    </div>
  );
};
