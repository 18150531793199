import styled from 'styled-components'
import { DESKTOP_1366, DESKTOP_1200, TABLET_992,  TABLET_800, TABLET_600, MOBILE_460, MOBILE_380 } from '../../styles/sizes'

export const Container = styled.div`  
  background-color: #F8F8F8;
`

export const InfoContainer = styled.div`
  margin: 0 auto;
  text-align:center;
  max-width: 1366px;
  width: 90%;
  display: flex;
  flex-direction: row;
  padding: 90px 0;
  justify-content: space-between;
  
  @media (max-width: ${TABLET_992}px){
    flex-direction: column;
    padding: 50px 0;
    align-items: center;
  }
`

export const VideoContainer = styled.div`
  width: 100%;
`

export const RelatedWorkContainer = styled.div`
  width: 100%;
`

export const TitlesSection = styled.div`
  width: 20%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${DESKTOP_1366}px){
    width: 26%;
  }
  @media (max-width: ${TABLET_992}px){
    width: 100%;
    margin-top: 25px;
    align-items: center;
  }
  @media (max-width: ${TABLET_600}px){
    margin-top: 0;
  }
`
export const InfoSection = styled.div`
  display: flex;
  width: 70%;
  text-align: left;
  font-size: 18px;
  color: #223238;

  @media (max-width: ${DESKTOP_1366}px){
    width: 65%;
  }
  @media (max-width: ${TABLET_992}px){
    margin-top: 10px;
  }
  @media (max-width: ${TABLET_600}px){
    margin: 0px;
    width: 100%;
  }
`

export const ClientTitle = styled.div<IDivProps>`
  color: #000000;
  font: bold 25px/33px 'Montserrat-Bold';
  margin-top: ${(props) => props.hasMarginTop && '30px'};
  align-self: flex-start;
  font-variation-settings: 'wght' 600;
  letter-spacing: 0.5px;

  @media (max-width: ${TABLET_992}px){
    margin-top: ${(props) => props.hasMarginTop && '20px'};
    width: 100%;
  }
  @media (max-width: ${TABLET_600}px){
    font-size: ${(props) => props.isBigger && '30px'};
    text-align: left;
    margin-top: ${(props) => props.hasMarginTop && '15px'};
    font-variation-settings: 'wght' 500;
  }
`

export const Website = styled.a`
  color: #FF0000;
  font: 300 21px/24px "Montserrat";
  align-self: flex-start;
  margin-top: 7px;
  text-decoration: none;
  text-align: left;
  text-wrap: nowrap;

  & :visited {
    color: #FF0000;
    text-decoration: none;
  }

  @media (max-width: ${TABLET_992}px){
    width: 100%;
    text-align: center;
  }
  @media (max-width: ${TABLET_600}px){
    width: 100%;
    text-align: left;
  }
`

export const LogoSection = styled.div`
  width: 185px;
  margin: 15px 0 0;
  display: flex;

  @media (max-width: ${TABLET_600}px){
    margin: 15px auto 0 0 !important;
  }
`

export const Categories = styled.p`
  color: #000000;
  font: 300 19px/24px "Montserrat";
  align-self: flex-start;
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-top: 7px;
  font-variation-settings: 'wght' 300;
  white-space: break-spaces;

  @media (max-width: ${TABLET_992}px){
    width: 65%;
    align-self: center;
    text-align: center;
  }
  @media (max-width: ${TABLET_600}px){
    width: 100%;
    align-self: flex-start;
    text-align: left;
  }
`

interface IDivProps {
  secondText?: boolean,
  isActive?: boolean,
  hasMarginTop?:boolean,
  isBigger?: boolean,
  hasMarginLeft?:boolean
}
