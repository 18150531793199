import styled from "styled-components";

export const Container = styled.div<INavigationButtonProps>`
  .swiper-button-prev,
  .swiper-button-next {
    color: ${(props) => props.buttonColor && props.buttonColor};
  }
`

interface INavigationButtonProps {
  buttonColor?: string;
}