import React from "react";
import { GatsbyImage,getImage } from 'gatsby-plugin-image';


import {DOMAIN} from '../../config'

import more from '../../animations/more.json'
import { LottiePlayer } from "../commons/LottiePlayer";

import { Container, Mask, Picture, TextContainer, CardTitle, CardText, Redirect, GatsbyImageContainer } from './styles'
import internal from "stream";

export const HoverableCard: React.FunctionComponent<IWorkCardLandingProps> = ({isFromWorkTemplate, indexPosition, onMouseEnter, onMouseLeave, hover, dataProps, isSeeMore, id}) => {
    
    
    let data;
    if(!isFromWorkTemplate)
    {
        const {attributes,...properties} = dataProps
        data = {...attributes,...properties}
        
    }
    else{
        data=dataProps
        data.id = id;
    }

    const relatedImage = getImage(data.Thumbnail_Image.data.attributes.urlSharp);
   
    return (
        <Container hover={hover} indexPosition={indexPosition} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} hover={hover} isSeeMore={isSeeMore}>
                <Redirect className="Redirect" to={`/work/${data.Project_Title.replace(/\s/g, '_').toLowerCase().replace('&','and')}`}> 
                    <Mask />
                    <TextContainer>
                        <LottiePlayer
                            play={hover}
                            animation_height={'70px'}
                            animation_width={'70px'}
                            animation_data={more as any}
                            />
                        <CardTitle longText={data.Project_Title.length>20}>{data.Project_Title}</CardTitle>
                        <CardText>{data.Subtitle}</CardText>
                    </TextContainer>
                    <Picture hover={hover}>
                        <GatsbyImage className="test" image={relatedImage} style={{ position: `static`, transition: `transform 0.5s` }} alt={'image'} />
                    </Picture>
                </Redirect>
        </Container>
    )
}

interface IWorkCardLandingProps {
    indexPosition?: string,
    hover: boolean;
    isSeeMore?: boolean;
    isFromWorkTemplate?:boolean;
    onMouseEnter: any;
    onMouseLeave: any;
    id: number;
    dataProps: {
        id: number;
        imagen: string;
        src: string;
        img: any,
        avifImage: any,
        imgTransition?: string,
        Project_Title: string,
        text: string,
        projectLink: string,
        subtitle?: string,
        Thumbnail_Image?: any
    }
}
