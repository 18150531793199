import styled from "styled-components";

import { DESKTOP_1200, TABLET_1023, TABLET_800, MOBILE_460 } from "../../styles/sizes";

export const Container = styled.div`
    width: 100%;
    background-color: #F8F8F8;
    padding: 80px 0;
    box-sizing: border-box;

    @media (max-width: ${TABLET_1023}px) {
        padding: 80px 20px;
    }
    @media (max-width: ${MOBILE_460}px) {
        padding: 40px 0;
    }
`

export const RelatedContainer = styled.div`
    width: 100%;
    //margin-top: 142px;
    margin: 0 auto;
    text-align:center;
    max-width: 1200px;
    min-width: min(40vw, 1600px);
    position: relative;
    background-color: #F8F8F8;
    
    @media (max-width: ${DESKTOP_1200}px) {
        max-width: 1000px;
    }
`

export const WorksSectionMobile = styled.div`
    //width: 100%;
    //padding-top: 142px;
    height: auto;
    padding: 0;
    flex-direction: column;
    grid-auto-rows: 1fr; 
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    display: grid;
   

    @media (max-width: ${TABLET_800}px) {
        grid-template-columns: 1fr;
        margin-top: 40px;
        padding: 0 10px;
    }
`

export const Title = styled.div`
    position: absolute;
    top: 0;
    font: bold 55px/70px 'Montserrat-Bold';
    color: #0D0D0D;
    font-variation-settings: 'wght' 500;

    @media (max-width: ${TABLET_1023}px) {
        font: bold 48px/58px 'Montserrat-Bold';
    }
    @media (max-width: ${TABLET_800}px) {
        position: unset;
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 55px;
    }
    
    @media (max-width: ${MOBILE_460}px) {
        font-size: 35px;
    }
`

interface IdivProps {
    isMessage?: boolean,
    lessMarginBottom?: boolean
}
