export const CleanStringAndCovertToArray = (worksArray:Array<any>) => {
    worksArray.map((work: any) => {
        if (typeof work.attributes.Categories === 'string') {
            work.attributes.Categories = work.attributes.Categories.toLowerCase().split('-').join("").split('/').join("").split('.').join("").replace(/_/g, "").replace(/\s/g, '')
            work.attributes.Categories = work.attributes.Categories.split(',')
        }
    })
    return worksArray;
};

export const StringToArray = (categories:any) => {
        categories = StringClean(categories);
        const categoriesArray = categories.split(',')
    return categoriesArray;
};

export const StringClean = (string?:any) => {
    string = string?.toLowerCase().split('-').join("").split('/').join("").split('.').join("").replace(/_/g, "").replace(/\s/g, '');
    return string;
};