import React, { createContext, useState } from "react";
import { TABLET_768 } from "../styles/sizes";

export const MouseContext = createContext({
  cursorType: "hoverOff",
  cursorChangeHandler: (cursorType: any) => {},
});

const MouseContextProvider = (props: any) => {
  const [cursorType, setCursorType] = useState("hoverOff");
  
  const cursorChangeHandler = (cursorType: any) => {
    if (typeof window !== `undefined` && window.innerWidth < TABLET_768) {
      return setCursorType("off");
    }

    setCursorType(cursorType);
  };

  return (
    <MouseContext.Provider
      value={{
        cursorType: cursorType,
        cursorChangeHandler: cursorChangeHandler,
      }}
    >
      {props.children}
    </MouseContext.Provider>
  );
};

export default MouseContextProvider;