export const BLACK = '#000'
export const DARK_GREY = '#333333'
export const LIGHT_GREY = '#F7F7F7 '
export const WHITE = '#FFFFFF'
export const PEARL = '#FCFCFC'

export const PRIMARY_COLOR = '#EC3E2C'
export const PRIMARY_BRIGHT_COLOR = '#ED1F10'
export const PRIMARY_DARK_COLOR = '#E11G10'

export const SECONDARY_COLOR = '#EC3E2C'
export const SECONDARY_BRIGHT_COLOR = '#ED1F10'
export const SECONDARY_DARK_COLOR = '#E11G10'

export const TEXT_COLOR = '#EC3E2C'
export const ALT_TEXT_COLOR = '#ED1F10'
