import styled, { css, keyframes } from "styled-components";

import {
  MOBILE_460,
  TABLET_768,
  MOBILE_380,
  DESKTOP_1200,
  TABLET_740,
  MOBILE_500,
  DESKTOP_1024,
} from "./sizes";

const fading = keyframes`
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0.4;
  }
`;

export const ControlsContainer = styled.div<{ visible: boolean }>`
  display: block;
  position: absolute;
  bottom: calc(35% - 80px);
  text-align: center;
  cursor: pointer;
  width: 160px;
  height: 160px;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s;
  visibility: hidden;
  pointer-events: none;
  ${({ visible }) =>
    visible &&
    `
      opacity: 1;
      pointer-events: unset;
      visibility: visible;
  `}
`;

export const CultureHeroContainer = styled.div`
  position: relative;
  padding-top: 150px;
  z-index: 10;
  @media (max-width: ${MOBILE_500}px) {
    padding-top: 100px;
  }
`;

export const Title = styled.div`
  font: 500 100px/90px "Montserrat";
  text-align: center;
  font-variation-settings: "wght" 700;
  color: white;
  word-break: keep-all;
  align-self: center;
  text-align: center;
  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 8vw;
    line-height: 75px;
  }
  @media (max-width: ${TABLET_740}px) {
    font: normal 55px/80px "Montserrat";
  }
  @media (max-width: ${MOBILE_380}px) {
    font: normal 45px/60px "Montserrat";
  }
`;

export const PageContainer = styled.div<IPageContainerProps>`
  width: 100%;
  height: 100%;
  transition: background 0.3s;
  box-sizing: border-box;
`;

export const StaticMiscellaneous = styled.img<IMiscellaneousProps>`
  position: absolute;
  max-width: 50vw;
  max-height: 100%;
  z-index: 0;
  width: ${(props) => props.width || "100%"};
  @media (max-width: ${TABLET_740}px) {
    width: ${(props) => props.mobileWidth || "100%"};
    display: ${(props) => props.mobileDisplay || "block"};
  }
`;

export const AnimatedMiscellaneous = styled.img<IMiscellaneousProps>`
  position: absolute;
  animation-name: ${fading};
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  width: ${(props) => props.width || "100%"};
  @media (max-width: ${TABLET_740}px) {
    width: ${(props) => props.mobileWidth || "100%"};
    display: ${(props) => props.mobileDisplay || "block"};
  }
`;

export const CulturePageContainer = styled.div<IPageContainerProps>`
  width: 100%;
  height: 100%;
  transition: background 0.2s;
  background: ${(props) => props.background};
  padding: 10% 5% 20px;
  box-sizing: border-box;
`;

export const GradientContainer = styled.div<IPageContainerProps>`
  background: ${(props) => props.background};
  transition: opacity 0.1s;
  height: 100%;
`;

export const ContainerOrgChart = styled.div<IPageContainerProps>`
  ${(props) => props.margin && `margin: ${props.margin}`};

  @media (max-width: ${DESKTOP_1024}px) {
    ${(props) => props.marginMobile && `margin: ${props.marginMobile}`};
  }

  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
`;

export const ContainerOrgChartMobile = styled.div<IPageContainerProps>`
  display: none;

  @media (max-width: ${TABLET_740}px) {
    display: block;
  }
`;

const floating = keyframes`
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   

`;

const rotate = keyframes`
    0% { transform: rotate(0deg); }
    100%   { transform: rotate(360deg); }   

`;

interface IPageContainerProps {
  background?: string;
  margin?: string;
  marginMobile?: string;
}

interface IMiscellaneousProps {
  width?: string;
  mobileWidth?: string;
  mobileDisplay?: string;
}

export const HeroVideoContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
`;

export const HeroMobileVideoContainer = styled.div`
  width: 100%;
  height: 80vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: none;
  min-height: 500px;

  @media (max-width: ${TABLET_740}px) {
    display: flex;
  }
`;

export const VideoGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
export const Video = styled.video<IVideoProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: ${MOBILE_500}px) {
    width: auto;
    max-width: none;
    height: 100vh;
  }
`;

export const VideoMobile = styled.video<IVideoProps>`
  width: 100%;
  object-fit: cover;
`;

export const VideoWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: ${TABLET_740}px) {
    display: flex;
  }
  @media (max-width: ${MOBILE_500}px) {
    height: 120vh;
  }
`;

export const CloseImageMobile = styled.img`
  position: fixed;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin-bottom: 68vw;
  right: 5px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

export const CloseImage = styled.img<{ visible: boolean }>`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 70px;
  width: 50px;
  right: 35px;
  height: 50px;
  padding: 5px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  opacity: 0;
  transition: all 0.5s;
  visibility: hidden;
  pointer-events: none;
  ${({ visible }) =>
    visible &&
    `
      opacity: 1;
      pointer-events: unset;
      visibility: visible;
  `}
`;

export const WrapperImage = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 27.5%;
  right: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
`;

interface IVideoProps {
  mobile?: boolean;
}

export const HeroTextContainer = styled.div`
  position: absolute;
  width: 100%;
  color: white;
`;

export const HeroTitle = styled.h1`
  text-align: center;
  font-family: "Montserrat";
  font-size: 64px;
  line-height: 68px;
  font-variation-settings: "wght" 700;
  word-break: keep-all;
  display: none;
  @media (max-width: ${MOBILE_460}px) {
    font-size: 34px;
    line-height: 38px;
  }
`;

export const TitleImage = styled.img`
  height: 100px;
  position: relative;
  bottom: 70px;
  max-width: 90vw;
`;

export const SectionTitle = styled.h2<ISectionTitleProps>`
  text-align: ${(props) => (!props.center ? "left" : "center")};
  font-family: "Montserrat";
  color: ${(props) => props.color && "#333333"};
  font-size: ${(props) => (!props.center ? "45px" : "30px")};
  line-height: ${(props) => (!props.center ? "62px" : "37px")};
  font-variation-settings: "wght" 700;
  word-break: keep-all;

  ${({ smallText }) =>
    smallText &&
    `
    font-size: 30px;`}

  @media (max-width: ${DESKTOP_1024}px) {
    text-align: center;
  }

  @media (max-width: ${TABLET_768}px) {
    padding:50px 15px 0 
    font-size: 38px;
    line-height: 40px;
    ${({ smallText }) =>
      smallText &&
      `
        font-size: 25px;`}
  }
  @media (max-width: ${MOBILE_500}px) {
    font-size: 38px;
    line-height:48px
  }
`;
interface ISectionTitleProps {
  center?: boolean;
  color?: boolean;
  smallText?: boolean;
}

export const HeroSubtitle = styled.h2`
  text-align: left;
  max-width: 500px;
  font-family: "Montserrat";
  font-size: 24px;
  font-variation-settings: "wght" 200;
  word-break: keep-all;
  line-height: 32px;
  margin-top: 8px;
`;

export const MouseContainer = styled.div`
  position: absolute;
  bottom: 50px;
  text-align: left;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayContainer = styled.div`
  position: absolute;
  bottom: calc(35% - 80px);
  text-align: center;
  cursor: pointer;
  width: 160px;
  height: 160px;
  z-index: 1;
`;

export const CarrouselContainer = styled.div`
  height: 325px;
  padding: 60px 0 120px;
  width: 100%;
  justify-content: center;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  @media (max-width: ${DESKTOP_1024}px) {
    padding: 60px 0 10px;
  }
`;

export const CarrouselTitle = styled.div`
  color: #000;
  font-family: "Montserrat";
  font-size: 50px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  margin-bottom: 50px;
`;

export const MouseImage = styled.img`
  height: 35px;
  animation: 1.5s ${floating} ease-in-out infinite;
`;
export const MouseText = styled.div`
  color: white;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 200;
  line-height: 26px;
  margin-left: 10px;
`;

export const PlayRound = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: auto;
  animation: 15s ${rotate} linear infinite;
  left: 0;
  max-width: 90vw;
`;

export const DefaultPlayImage = styled.img<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  left: 0;
  max-width: 90vw;
  &:hover {
    opacity: 0.5;
  }
`;

export const PlayImage = styled.img<{ isPaused: boolean }>`
  box-sizing: border-box;
  position: absolute;
  width: 80%;
  height: 80%;
  margin: auto;
  left: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  top: 40px;
  max-width: 90vw;
  padding: 10px;
  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
  }

  ${(props) =>
    props.isPaused &&
    `
    `}
`;

export const BackImage = styled.img<any>`
  box-sizing: border-box;
  position: absolute;
  width: 40%;
  height: 40%;
  margin: auto;
  right: 105%;
  margin-top: 30%;
  max-width: 90vw;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  top: 30px;
  padding: 10px;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  ${(props) =>
    props.isPaused &&
    `

    `}
`;

export const RestartImage = styled.img<any>`
  box-sizing: border-box;
  position: absolute;
  width: 40%;
  height: 40%;
  margin: auto;
  right: -25%;
  margin-top: 30%;
  max-width: 90vw;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 30px;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  ${(props) =>
    props.isPaused &&
    `

    `}
`;
