import styled from 'styled-components'
import { TABLET_992,  TABLET_800, TABLET_600, MOBILE_460, MOBILE_380 } from '../../styles/sizes'

export const WorkContainer = styled.div`
  //min-width: 75vw;
  //max-width: 1200px;
  width: 100%;
  //margin: 0 auto;
`

export const TestIMage = styled.div`
  //min-width: 75vw;
  //max-width: 1200px;
  width: 400px;
  height: 400px;
  //margin: 0 auto;
  background-color: #F8F8F8;
`


export const HeroContainer = styled.div`
  width: 100%;
  margin-top: 90px;
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`


export const RelatedWorkContainer = styled.div`
  width: 100%;
`

export const TitlesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`
export const InfoSection = styled.div`
  width: 100%;
`

export const ClientTitle = styled.div<IDivProps>`
  color: #000000;
  font-size: 25px;
  font-family: "Montserrat";
  font-weight: bold;
  margin-top: ${(props) => props.hasMarginTop && '30px'};
`

export const Website = styled.div`
  color: #FF0000;
  font-size: 25px;
  font-family: "Montserrat";
`

export const Categorie = styled.div`
  color: #000000;
  font-size: 25px;
  font-weight: 300;
  font-family: "Montserrat";
`

interface IDivProps {
  secondText?: boolean,
  isActive?: boolean,
  hasMarginTop?:boolean
}
