import React from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from './carrousel.styles'
import { CarrouselProps } from './carrousel.types'
import { GatsbyImage, getImage} from 'gatsby-plugin-image';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


export const Carrousel = ({slidersArray, buttonColor}: CarrouselProps) => {
    return(
        <Container buttonColor={buttonColor}>
            <Swiper
                loop={true}
                autoplay={false}
                navigation={true}
                speed={700}
                slidesPerView={1}
                spaceBetween={30}
                breakpoints= {{
                    210: {
                    slidesPerView: 1,
                    spaceBetween: 20
                    },
                    480: {
                    slidesPerView: 1,
                    spaceBetween: 30
                    },
                    640: {
                    slidesPerView: 1,
                    spaceBetween: 30
                    }
                }
                }
                modules={[Autoplay, Navigation]}
                className="mySwiper"
            >
                {slidersArray.map((slider) => (
                    <SwiperSlide>
                        <GatsbyImage image={getImage(slider.Carrousel_Slider_Media.data.attributes.urlSharp)} alt={'carrousel'} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container>
    )
}