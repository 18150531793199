import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";

import { useIntl } from "gatsby-plugin-intl";

import SEO from "../components/SEO/SEO";

import { Layout } from "../components/Layout";

import { PeopleSlider } from "../sections/PeopleSlider";
import { AnimatedTitle } from "../components/commons/AnimatedTitle";
import { WhoWeAre } from "../sections/WhoWeAre";
import { ThisIsUs } from "../sections/ThisIsUs";
import { HowWeDoIt } from "../sections/HowWeDoIt";
import { Stats } from "../sections/Stats";

import patternQuotes from "../images/culture/miscellaneous/quotes.svg";
import patternHowWeDoIt from "../images/culture/miscellaneous/how-we-do-it.svg";
import patternOurVision from "../images/culture/miscellaneous/our-vision.svg";
import patternOurVision2 from "../images/culture/miscellaneous/our-vision-2.svg";

import {
  HeroTextContainer,
  HeroTitle,
  HeroVideoContainer,
  MouseContainer,
  MouseImage,
  Video,
  VideoGradient,
  TitleImage,
  PageContainer,
  PlayRound,
  RestartImage,
  DefaultPlayImage,
  BackImage,
  PlayContainer,
  PlayImage,
  MouseText,
  SectionTitle,
  CarrouselContainer,
  StaticMiscellaneous,
  VideoWrapper,
  VideoMobile,
  HeroMobileVideoContainer,
  CloseImage,
  WrapperImage,
  ControlsContainer,
  CloseImageMobile,
} from "../styles/about-us-revamp";
import { BackgroundWrapper, Container } from "../styles/commons";

import videoMp4 from "../videos/about-us-hero.mp4";
import videoMobile from "../videos/about-us-hero-mobile.mp4";

import videoPoster from "../images/culture/poster.png";
import MouseImg from "../images/home/mouse-icon.svg";
import PlayImg from "../images/culture/play.svg";
import PauseImg from "../images/culture/pause.svg";
import RestartImg from "../images/culture/restart.svg";
import SoundOnImg from "../images/culture/sound_on.svg";
import CloseImg from "../images/culture/close.svg";
import SoundOffImg from "../images/culture/sound_off.svg";
import RoundImg from "../images/culture/round.svg";
import TitleImg from "../images/culture/title.svg";
import { Services } from "../sections/AboutUsServices";
import { AwardsCarrousel } from "../sections/Awards";
import { OurVision } from "../sections/OurVision";
import { AboutUsContactForm } from "../sections/AboutUsContactForm";
import { BackedBy } from "../sections/BackedBy";

export const query = graphql`
  query {
    cms {
      achievementsLogos {
        data {
          attributes {
            LogoTitle
            LogoSubtitle
            LogoSubtitleLine2
            LogoImage {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      }
      quotes(
        pagination: { limit: 200 }
        filters: { type: { eq: "Clients" } }
      ) {
        data {
          attributes {
            author
            position
            quote_text
            type
          }
        }
      }
    }
  }
`;

const Home = ({ data }: any) => {
  const intl = useIntl();

  const videoRef = useRef(null);

  const defaultBackgroundColor = "white";
  const [backgroundColor, setBackgroundColor] = useState<string>(
    defaultBackgroundColor
  );


  const playPauseHeroVideo = (isPlaying: boolean) => {
    if (videoRef.current) {
      if(isPlaying){
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };

  const restarHeroVideo = () => {
    if (videoRef.current) {
        setPauseHero(false);
        videoRef.current.currentTime = 0;
      }
  };


  const [runHero, setRunHero] = useState<boolean>(false);
  const [runHeroMobile, setRunHeroMobile] = useState<boolean>(false);
  const [pauseHero, setPauseHero] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(null);
  const [keepControlsVisible, setKeepControlsVisible] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const keepControlsVisibleRef = useRef(keepControlsVisible);
  const timeoutRef = useRef(null);


  useEffect(() => {
    keepControlsVisibleRef.current = keepControlsVisible;
  }, [keepControlsVisible]);


  // Disabling Scroll on Mobile PopUP
  useEffect(() => {
    function preventDefault(e) {
      e = e || window.event;
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
    var preventKeys: any = {
      37: 1,
      38: 1,
      39: 1,
      40: 1,
    };
    function preventDefaultForScrollKeys(e) {
      if (preventKeys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }
    function disableScroll() {
      var target = document.getElementById("___gatsby");
      if (target) {
        target.addEventListener("DOMMouseScroll", preventDefault, false);
        target.onwheel = preventDefault; // modern standard
        target.ontouchmove = preventDefault; // mobile
        target.onkeydown = preventDefaultForScrollKeys;
      }
    }
    function enableScroll() {
      var target = document.getElementById("___gatsby");
      if (target) {
        target.removeEventListener("DOMMouseScroll", preventDefault, false);
        target.onwheel = null;
        target.ontouchmove = null;
        target.onkeydown = null;
      }
    }

    if (runHeroMobile) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [runHeroMobile]);

  useEffect(() => {
    playPauseHeroVideo(pauseHero);
  }, [pauseHero]);

  useEffect(() => {
    if(keepControlsVisibleRef.current){
      renewControlsTime();
    }
  }, [keepControlsVisibleRef.current]); 

  const hideControls = () => {
    if(keepControlsVisibleRef.current){
      renewControlsTime();
    } else {
      if(pauseHero){
        renewControlsTime();
      } else {
        setShowControls(false);
      }
    }
  };

  const renewControlsTime = () => {
    clearTimeout(timeoutRef.current);
    setShowControls(true);
    timeoutRef.current = setTimeout(() => {
      hideControls();
    }, 1000);
  };

  useEffect(() => {
    // Clean up the timer when the component is unmounted
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const quotes = data.cms.quotes.data;

  return (
    <Layout initialTextColor="white" landing="about-us" backgroundColor="black">
      <SEO
        pageTitle={intl.formatMessage({ id: "culture_seo_title" })}
        description={intl.formatMessage({ id: "culture_seo_description" })}
        twitterSite="@wearepsh"
        twitterTitle={intl.formatMessage({ id: "culture_seo_title" })}
        ogUrl="https://wearepsh.com/about-us"
        ogDescription={intl.formatMessage({ id: "culture_seo_description" })}
        ogImage={"https://wearepsh.com/images/seo_image.png"}
      />
      <PageContainer id="ourCulture" style={{ background: backgroundColor }}>
        <HeroVideoContainer>
          <MouseContainer>
            <Container
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MouseImage src={MouseImg} />
              <MouseText>Scroll To Learn</MouseText>
            </Container>
          </MouseContainer>
          {(!runHero) && (
            <PlayContainer onClick={() => setRunHero(true)}>
                <PlayRound src={RoundImg} />
                <DefaultPlayImage
                key="defaultPlay"
                src={PlayImg}
                  />
            </PlayContainer>
          )}
          {runHero &&
          <>

<CloseImage
key="close"
onMouseEnter={() => {runHero && setKeepControlsVisible(true)}}
onMouseLeave={() => {runHero && setKeepControlsVisible(false)}}
onClick={
  () => setRunHero(false)
}
src={CloseImg}
visible={showControls}
/>
            <ControlsContainer visible={showControls}>
              <PlayImage
                key="play"
                onMouseEnter={() => {runHero && setKeepControlsVisible(true)}}
                onMouseLeave={() => {runHero && setKeepControlsVisible(false)}}
                isPaused={pauseHero}
                onClick={
                  () => setPauseHero(!pauseHero)
                }
                src={pauseHero ? PlayImg : PauseImg}
              />
                <BackImage
                key="back"
                onMouseEnter={() => {runHero && setKeepControlsVisible(true)}}
                onMouseLeave={() => {runHero && setKeepControlsVisible(false)}}
                isPaused={pauseHero}
                onClick={
                  () => { restarHeroVideo();}
                }
                src={RestartImg}
              />
                              <RestartImage
                              key="restart"
                onMouseEnter={() => {runHero && setKeepControlsVisible(true)}}
                onMouseLeave={() => {runHero && setKeepControlsVisible(false)}}
                isPaused={pauseHero}
                onClick={
                  () => setIsMuted(!isMuted)
                }
                src={isMuted ? SoundOffImg : SoundOnImg}
              />
              
            </ControlsContainer>
            </>
                    }
          <VideoGradient />
          {runHero && (
            <>
              <Video
                onMouseMove={renewControlsTime}
                ref={videoRef}
                poster={videoPoster}
                autoPlay
                muted={isMuted}
                playsInline
                onEnded={() => setRunHero(false)}
              >
                <source src={videoMp4} type="video/mp4" />
              </Video>
            </>
          )}
          {!runHero && (
            <>
            <Video poster={videoPoster} loop muted playsInline>
                <source src={videoMp4} type="video/mp4" />
              </Video>
            </>
          )}
          <HeroTextContainer>
            <Container>
              {!runHero && <TitleImage src={TitleImg} />}
              <HeroTitle id="hero">We are PSh</HeroTitle>
            </Container>
          </HeroTextContainer>
        </HeroVideoContainer>

        <HeroMobileVideoContainer>
          <MouseContainer>
            <Container
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MouseImage src={MouseImg} />
              <MouseText>Scroll To Learn</MouseText>
            </Container>
          </MouseContainer>
          {(!runHeroMobile) && (
      <PlayContainer                      onClick={() => setRunHeroMobile(true)}>
                <PlayRound
                  src={RoundImg}
                />
              <DefaultPlayImage
                src={PlayImg}
              />
            </PlayContainer>
          )}
          <VideoGradient />
          {runHeroMobile &&
            <>
              <Video poster={videoPoster} loop muted playsInline>
                <source src={videoMp4} type="video/mp4" />
              </Video>
              <VideoWrapper>
                <VideoMobile
                  ref={videoRef}
                  poster={videoPoster}
                  loop
                  autoPlay
                  muted
                  playsInline
                  controls
                  controlsList="nofullscreen"
                >
                  <source src={videoMp4} type="video/mp4" />
                </VideoMobile>
                  <CloseImageMobile
                    src={CloseImg}
                    onClick={() => 
                      setRunHeroMobile(false)
                    }
                  />
              </VideoWrapper>
            </>
          }
          {!runHeroMobile && (
            <>
              <Video poster={videoPoster} loop muted playsInline>
                <source src={videoMobile} type="video/mp4" />
              </Video>
            </>
          )}
          <HeroTextContainer>
            <Container>
              {!runHeroMobile && <TitleImage src={TitleImg} />}
              <HeroTitle id="hero">We are PSh</HeroTitle>
            </Container>
          </HeroTextContainer>
        </HeroMobileVideoContainer>

        <div style={{ position: "relative" }}>
          <WhoWeAre />
        </div>

        <BackgroundWrapper background="#F8F8F8">
          <Container
            paddingBottom="40px"
            paddingTop="60px"
            paddingTopMobile="30px"
            paddingBottomMobile="30px"
          >
            <AnimatedTitle height="45px" heightMobile="55px" lineDelay={0.3}>
              <SectionTitle
                smallText
                center
                marginMobile={"15px auto auto auto"}
                color={"#333333"}
              >
                We are backed by
              </SectionTitle>
            </AnimatedTitle>
            <BackedBy />
          </Container>
        </BackgroundWrapper>

        <Container style={{ position: "relative" }}>
          <ThisIsUs />
        </Container>

        <Container paddingTop="20px" paddingBottom="50px">
          <AnimatedTitle height="53px" heightMobile="45px" lineDelay={0.3}>
            <SectionTitle id="scrollSection">What We Do</SectionTitle>
          </AnimatedTitle>
          <Services />
        </Container>

        <div style={{ position: "relative" }}>
          <StaticMiscellaneous
            style={{ width: "30%", bottom: "0", left: "-10px" }}
            src={patternHowWeDoIt}
          />
          <Container paddingTop="20px" paddingBottom="50px">
            <HowWeDoIt />
          </Container>
        </div>
        <div style={{ position: "relative" }}>
          <Stats />
        </div>

        <CarrouselContainer>
          <Container>
            <AnimatedTitle heightTablet='30px'  height="40px" heightMobile="45px" lineDelay={0.3}>
              <SectionTitle smallText center id="awards" color={"#333333"}>
                Our Achievements
              </SectionTitle>
            </AnimatedTitle>
            <AwardsCarrousel items={data.cms.achievementsLogos.data} />
          </Container>
        </CarrouselContainer>

        <div style={{ position: "relative" }}>
          <StaticMiscellaneous
            style={{ width: "40%", top: "0", right: "-10px" }}
            src={patternOurVision}
          />
          <StaticMiscellaneous
            style={{ width: "40%", bottom: "0", left: "-27%" }}
            src={patternOurVision2}
          />
          <OurVision />
        </div>
        <div style={{ position: "relative", zIndex: 5 ,background: '#f8f8f8'}}>
          <StaticMiscellaneous
            style={{ width: "17%", top: "-15%", right: "0" }}
            src={patternQuotes}
          />
          <Container
            paddingBottom="80px"
            paddingTop="80px"
            paddingTopMobile="30px"
          >
            <div style={{ position: "relative", zIndex: 5 }}>
              <SectionTitle marginMobile={"15px auto auto auto"}>
                What People Say About Us
              </SectionTitle>
              <div style={{ position: "relative", zIndex: 10 }}>
                <PeopleSlider
                  quotes={quotes}
                  activeCategory="EMPLOYEES"
                  fontColor={
                    backgroundColor !== "#e52321" ? "#223238" : "white"
                  }
                />
              </div>
            </div>
          </Container>
        </div>
        <AboutUsContactForm></AboutUsContactForm>
      </PageContainer>
    </Layout>
  );
};

export default Home;
