import React, { useState, useRef, useEffect } from 'react';

import { useInView } from 'react-hook-inview';

import redDots from '../../animations/redDots.json';

import {StringToArray, StringClean} from '../../helpers/CategoriesHelper'

import { AnimatedTitle } from '../../components/commons/AnimatedTitle';
import { HoverableCard } from '../../components/HoverableCard';
import { LoadingDots } from '../../components/commons/LoadingDots';

import upArrow from "../../images/arrowUp.svg";

import { Desktop, HeroTextContainer, Title, FilterSection, FilterList, FilterItem,  FilterBar, FilterIndicator, FilterSectionMobile, FilterItemMobile, CenteredDiv, WorksSectionMobile, WorksSection, LoadingContainer, ArrowIcon, SeeMore } from './styles';
import { IWorksProps } from './types.js';

const Works: React.FunctionComponent<IWorksProps> = ({ worksList, serviceCategory } : IWorksProps) => {
    worksList.sort((a, b) => a.attributes.Priority - b.attributes.Priority)
    //worksList = worksList.filter(work=> work.attributes?.Main_Project_Name?.toLowerCase() == work.attributes?.Project_Title.toLowerCase())

    const [hover, setHover] = useState<number>(-1); 
    const [category, setCategory] = useState<string>('0%');
    const [prevCategory, setPrevCategory] = useState<string>('10%');
    const [activeCategory, setActiveCategory] = useState<string>('all');
    const [maxIndexToBeDisplayed, setMaxIndexToBeDisplayed] = useState<number>(9);
    const [countSeeMore, setCountSeeMore] = useState<number>(1);
    const [seeMoreResults, setSeeMoreResults] = useState<Array<any>>([]);

    let index = 9;
    const indexFunction = () => {
        index = index + 9;
        clearTimeout();
        return setMaxIndexToBeDisplayed(index);
    }
    const [ref] = useInView({
        threshold: 0,
        rootMargin: '-20%',
        unobserveOnEnter: false,
        onEnter: () => {setTimeout(() => indexFunction(), 500);}, 
    })

    const countRef = useRef(hover);
    countRef.current = hover;
    const handleMouseLeave = (workId: number) => {
        const timer = setTimeout(() => {
            if(countRef.current === workId){
                setHover(-1)
            }
        return() => clearTimeout(timer);
        }, 300);
    }
    const handleMouseEnter = (index: number) => {
        setHover(index)
    }
    const returnRow = (index: number) => {
        switch (index) {
            case 0:
                return "index0";
            case 1: 
                return "index1";
            case 2:
                return "index2";
            default:
                return "others";
        }
    }





    // const identifyCategory = (Categories: any) => {
    //     const categoriesArray = StringToArray(Categories);
    //     if ( (categoriesArray.includes("socialmedia") || categoriesArray.includes("emailmarketing") || categoriesArray.includes("branding")) && (categoriesArray.includes("gamification") || categoriesArray.includes("mobile"))) {
    //         categoriesArray.push('Marketing Strategy','Development');
    //         return categoriesArray;
    //     } else if (categoriesArray.includes("gamification") || Categories.includes("mobile")){
    //         categoriesArray.push('Development')
    //         return categoriesArray;
    //     } if (categoriesArray.includes("socialmedia") || categoriesArray.includes("emailmarketing") || categoriesArray.includes("branding")){
    //         categoriesArray.push('Marketing Strategy')
    //         return categoriesArray;
    //     } else return categoriesArray;
    // }


    

    const handleClick = (percentage:string, clickCategory:string ) => {
        console.log(percentage,"percentage")
        console.log(clickCategory,"clickCategory")
        setCategory(percentage)
        setPrevCategory(category)
        setActiveCategory(clickCategory)
        setMaxIndexToBeDisplayed(9)
        if (window.innerWidth < 1024) {
            clickCategory = clickCategory == activeCategory ? "" : clickCategory
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
        setActiveCategory(clickCategory)
        setSeeMoreResults([]);
        setCountSeeMore(1);
    }
    const filterFunction = (activeCategory: string) => {
        let filteredResults = worksList;
        return activeCategory !== "all" ? filteredResults.filter(work => StringToArray(work.attributes.Categories).includes(StringClean(activeCategory)) ) : filteredResults;
    }

    const allList = filterFunction("all");
    const creativeList = filterFunction("Design & Creativity");
    const developmentList = filterFunction("Development & Engineering");
    const growthList = filterFunction("Growth Marketing");
    const contentProductionList = filterFunction("Content Production");
    const CategoryData = [
        {
            "categoryFilter": "all",
            "categoryName": "all",
            "percentage": "0%",
            "categoryList": allList,
        },
        {
            "categoryFilter": "Design & Creativity",
            "categoryName": "Design & Creativity",
            "percentage": "6.5%",
            "categoryList": creativeList,
        },
        {
            "categoryFilter": "Development & Engineering",
            "categoryName": "Software Development",
            "percentage": "26.5%",
            "categoryList": developmentList,
        },
        {
            "categoryFilter": "Growth Marketing",
            "categoryName": "Growth & Marketing",
            "percentage": "54.5%",
            "categoryList": growthList,
        },
        {
            "categoryFilter": "Content Production",
            "categoryName": "Content Production",
            "percentage": "77.5%",
            "categoryList": contentProductionList,
        },
    ]
    const foundObject = CategoryData.find(element => element.categoryFilter === activeCategory);
    const slicedWorksDesktop = (categoryList: Array<any>, index: number) => {
        if (categoryList.length > 9) {
            return categoryList.slice(0, index);
        } else return categoryList;
    }
    const slicedWorksMobile = (categoryList: Array<any>) => {
        if (categoryList.length > 5) {
            return categoryList.slice(0, 5);
        } else return categoryList;
    }
    const handleLoadMore = (categoryList: Array<any>, counter: number) => {
        if (categoryList.length-countSeeMore*5 > 1) {
            setSeeMoreResults((seeMoreResults) =>[...seeMoreResults,...categoryList.slice(countSeeMore*5, countSeeMore*5+5)])
        }
        setCountSeeMore(countSeeMore+1);
    }

    
    const serviceProp = () => {
        if(serviceCategory){
            handleClick(CategoryData[serviceCategory].percentage,CategoryData[serviceCategory].categoryFilter)
            window.history.replaceState(null, '')
        }
    }

    useEffect(() => {
        serviceProp()
      }, [])

    return (
        <>
            <HeroTextContainer>
                <AnimatedTitle height="83px" heightMobile="90px" lineDelay={0.3}>
                    <Title>Our Work</Title>
                </AnimatedTitle>
            </HeroTextContainer>
            <Desktop>
                <FilterSection>
                    <FilterList>
                        {
                            CategoryData.map((item: any, index: number) => {
                                return (
                                    <FilterItem key={"item" + item.categoryName} isActive={activeCategory === item.categoryFilter} onClick={() => handleClick(item.percentage, item.categoryFilter)}>{item.categoryName}</FilterItem>
                                );
                            })
                        }
                    </FilterList>
                    <FilterBar>
                        <FilterIndicator from={prevCategory} to={category} categoryWidth={activeCategory}/>
                    </FilterBar>
                </FilterSection>
                <WorksSection>
                    { 
                        foundObject && slicedWorksDesktop(foundObject.categoryList, maxIndexToBeDisplayed).map((item: any, index: number) => {
                            return (
                                <HoverableCard key={foundObject.categoryList[index].Project_Title} indexPosition={returnRow(index)} onMouseEnter={() => {handleMouseEnter(index)}} onMouseLeave={() => handleMouseLeave(index)} hover={hover === index} dataProps={item} />
                            );
                        })
                    }
                </WorksSection>
                {
                    foundObject && (foundObject.categoryList.length > 9) && (maxIndexToBeDisplayed <= worksList.length) ? 
                        <LoadingContainer ref={ref}>
                            <LoadingDots animation={redDots}/>
                        </LoadingContainer> 
                    :
                    <></>
                }
            </Desktop>
            <FilterSectionMobile>
                {CategoryData.map((item: any) => {
                    return (
                        <>
                            <FilterItemMobile isActive={activeCategory === item.categoryFilter} onClick={() => (handleClick(item.percentage, item.categoryFilter))}>{item.categoryName}<ArrowIcon src={upArrow} /></FilterItemMobile>
                            <CenteredDiv fiveMoreProjects={countSeeMore>1} isActive={activeCategory === item.categoryFilter}>
                                <WorksSectionMobile isActive={activeCategory === item.categoryFilter}>
                                    {
                                        (slicedWorksMobile(item.categoryList)).map((item: any, index: number) => {
                                            return (
                                                <HoverableCard key={item.Project_Title + "sliced works mobile"} indexPosition={returnRow(index)} onMouseEnter={() => {handleMouseEnter(index)}} onMouseLeave={() => handleMouseLeave(index)} hover={hover === index} dataProps={item} />
                                            );
                                        })
                                    }
                                </WorksSectionMobile>
                                <WorksSectionMobile isActive={activeCategory === item.categoryFilter} isSeeMore>
                                    {
                                        seeMoreResults && seeMoreResults.map((item: any, index: number) => {
                                            return (
                                                <HoverableCard key={item.Project_Title + "see more results"} indexPosition={returnRow(index)} onMouseEnter={() => {handleMouseEnter(index)}} onMouseLeave={() => handleMouseLeave(index)} hover={hover === index} dataProps={item} isSeeMore />
                                            );
                                        })
                                    }
                                    {
                                        item.categoryList.length-countSeeMore*5 > 1 ?
                                            <SeeMore onClick={() => handleLoadMore(item.categoryList,countSeeMore)} fiveMoreProjects={countSeeMore>1}>See More</SeeMore>
                                        :
                                            <></>
                                    }
                                </WorksSectionMobile>
                            </CenteredDiv>
                        </>
                    );
                })}
            </FilterSectionMobile>
        </>
    )}
export default Works;