import styled from "styled-components";
import { DESKTOP_1024, TABLET_600, MOBILE_460 } from "../../styles/sizes";
import { CardProps } from "./types";

export const Text = styled.div`
  max-width: 703px;
  text-align: left;
  margin-bottom: 20px;
  font: normal normal normal 24px/32px Montserrat;
  color: #1a1a1d;

  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  @media (max-width: ${TABLET_600}px) {
    font: normal 20px/30px "Montserrat";
  }
`;

export const Bold = styled.div`
  max-width: 703px;
  text-align: left;
  font: normal normal bold 24px/32px Montserrat;
  color: #1a1a1d;

  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 100%;
    text-align: center;
  }

  @media (max-width: ${TABLET_600}px) {
    font: normal 20px/30px "Montserrat";
  }
`;

export const TextContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 100%;
  }
`;


export const TextLottieContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0;

  @media (max-width: ${DESKTOP_1024}px) {
    flex-direction: column;
  }
`;


export const Title = styled.div`
  width: 100%;
  margin-bottom: 35px;
  text-align: left;
  font: normal normal bold 45px/55px Montserrat;
  color: #000;

  @media (max-width: ${DESKTOP_1024}px) {
    text-align: center;
    font: normal normal bold 38px/40px Montserrat;
  }
`;

export const LottieInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
  min-width: 40%;
  position: relative;

  @media (max-width: ${DESKTOP_1024}px) {
    justify-content: center;
  }

   @media (max-width: ${TABLET_600}px) {
    margin-top: 0px;
  }
`;

export const InfoCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoCard = styled.div<CardProps>`
  max-width: 475px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: start;
  border: 2px solid ${(props) => (props.openCard ? "#FF0000" : "#a5a5a5")};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 30px 30px 30px 40px;
  position: relative;

  transition: height 0.5s ease;

  @media (max-width: ${TABLET_600}px) {
    max-width: fit-content;
    width: 78vw;
  }
`;

export const Arrow = styled.img`
  position: absolute;
  right: 30px;
  top: 37px;
  width: 20px;
  height: 11px;
  cursor: pointer;

  @media (max-width: ${DESKTOP_1024}px) {
    right: 20px;
  }
`;

export const RedArrow = styled.img`
  position: absolute;
  right: 30px;
  top: 37px;
  width: 20px;
  height: 11px;
  transform: rotate(180deg);
  cursor: pointer;

  @media (max-width: ${DESKTOP_1024}px) {
    top: 85%;
    right: 20px;
  }
`;

export const Icon = styled.img`
  display: none;
  position: absolute;
  right: 30px;
  top: 10px;
  width: 76px;
  height: 66px;

  @media (max-width: ${DESKTOP_1024}px) {
    display: flex;
  }

  @media (max-width: ${MOBILE_460}px) {
    top: 25px;
  }
`;

export const CardTitle = styled.div<CardProps>`
  width: 482px;
  text-align: left;
  font: normal normal bold 20px/28px Montserrat;
  letter-spacing: 0px;
  color: ${(props) => (props.openCard ? "#000" : "#a5a5a5")};
  display: flex;
  text-align: left;

  @media (max-width: ${TABLET_600}px) {
    width: ${(props) => (props.openCard ? "85%" : "482px")}
  }
`;

export const CardText = styled.div`
  text-align: left;
  font: normal normal normal 16px/22px Montserrat;
  letter-spacing: 0px;
  color: #1a1a1d;
  display: flex;
  margin-top: 25px;
  text-align: left;

  @media (max-width: ${DESKTOP_1024}px) {
    margin-bottom: 30px;
  }
`;

export const SegmentedLottieContainer = styled.div<ILottieContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  @media (max-width: ${DESKTOP_1024}px) {
    display: none;
  }
`;

interface ILottieContainerProps {
  height: string;
  width: string;
}
