import styled from 'styled-components'

export const Image = styled.img`
  width: 500px;
  height: 500px;
`

export const ImageContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const Content = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 60px;
  font-weight: bold;
  color: #C02705;
`

export const Text = styled.div`
  color: #C02705;
  text-align: center;
`

export const RedirectButton = styled.a`
  color: #f4cb41;
  font-weight: bold;
  margin-top: 35px;
  text-align: center;
  padding: 20px 15px;
  background-color: #ed5932;
  transition: 0.4s;

  :hover {
   background-color: #FA6B4B ;
   color: #f4cb41;
  }
`
