import React, { useState } from "react";

import People from "./assets/People.svg";
import Performance from "./assets/Performance.svg";
import SmartWork from "./assets/SmartWork.svg";
import Sustainability from "./assets/Sustainability.svg";
import Synergy from "./assets/Synergy.svg";

import {
  Text,
  Container,
  Title,
  IconsContainer,
  ContentContainer,
} from "./styles";
import { CultureContainer } from "../../styles/commons";
import { Card } from "./card";

export const WhoWeAre: React.FC<IHowWeWork> = () => {
  return (
    <Container>
      <CultureContainer
        paddingTop="60px"
        paddingBottom="60px"
        paddingTopTablet="40px"
        paddingBottomTablet="40px"
        paddingTopMobile="50px"
        paddingBottomMobile="50px"
      >
        <ContentContainer>
          <Title>Who We Are</Title>
          <Text>
            As your strategic partner, we deliver innovative, high&#8209;quality
            digital solutions. Our dedicated and tailored teams based in
            Argentina combine cost&#8209;effectiveness and time flexibility,
            providing a competitive edge for your business with
            goal&#8209;driven talent.
          </Text>
          <IconsContainer>
            <Card
              key="Performance"
              title="Performance"
              text="We create value through innovative solutions that meet
                  customer needs."
              icon={Performance}
            />
            <Card
              key="Synergy"
              title="Synergy"
              text="Teamwork drives innovation. Collaboration amplifies skills for impactful results."
              icon={Synergy}
            />
            <Card
              key="Smart Work"
              title="Smart Work"
              text="We enhance efficiency through streamlined processes and technology."
              icon={SmartWork}
            />
            <Card
              title="Sustainability"
              text="We are focused on environment, social responsibility, and equity through sustainable practices."
              icon={Sustainability}
            />
            <Card
              key="People XP"
              title="People XP"
              text="We enhance people’s experience by identifying needs, crafting satisfying solutions."
              icon={People}
            />
          </IconsContainer>
        </ContentContainer>
      </CultureContainer>
    </Container>
  );
};

interface IHowWeWork {}
