import React, { useState } from "react";

import Design from "./assets/design.svg";
import Software from "./assets/software.svg";
import Growth from "./assets/growth.svg";
import MoreImg from "./assets/more.svg";
import CloseImg from "./assets/close.svg";

import {
  GridContainer,
  Container,
  NoWrapDiv,
  ServiceCard,
  CardContainer,
  Title,
  Text,ColorIllustrationContainer,
  ClickText,
  SeparatorCircle,
  ColorIllustration,
  MoreIcon,
  ClickTextImageContainer,
  ClickTextImage,
} from "./styles";

export const Services: React.FC = () => {
  const [isActiveCard1, setIsActiveCard1] = useState<boolean>(false);
  const [isActiveCard2, setIsActiveCard2] = useState<boolean>(false);
  const [isActiveCard3, setIsActiveCard3] = useState<boolean>(false);

  return (
    <Container>
      <GridContainer>
        <CardContainer isClickedMobile={isActiveCard1} onClick={() => setIsActiveCard1(!isActiveCard1)}>
          <ServiceCard className="card" isClickedMobile={isActiveCard1}>
            <ColorIllustrationContainer className="ColorIllustrationContainer">
              <ColorIllustration className="ColorIllustration" src={Design} />
            </ColorIllustrationContainer>
            <Title className="Title">
              Design & <br />
              Creativity
            </Title>
            <Text className="Text">
              UX{" "}
              <NoWrapDiv>
                <SeparatorCircle /> UI
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Product Design{" "}
              </NoWrapDiv>
              <br />
              <NoWrapDiv>
                <SeparatorCircle /> Mobile First{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Prototyping{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Motion Design{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Brand Identity{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Co-Creation
              </NoWrapDiv>
              <NoWrapDiv>
                {" "}
                <SeparatorCircle /> Display Art
              </NoWrapDiv>
            </Text>
            <ClickText className="ClickText">
              Create a strong foundation for your business. Enhance your brand
              voice and architecture your product positioning. We’ll lead your
              ideas to the next level.
            </ClickText>
            <ClickTextImageContainer className="ClickTextImageContainer">
              <ClickTextImage src={Design} />
            </ClickTextImageContainer>
            <MoreIcon src={isActiveCard1? CloseImg :MoreImg}/>
          </ServiceCard>
        </CardContainer>
        <CardContainer isClickedMobile={isActiveCard2} onClick={() => setIsActiveCard2(!isActiveCard2)}>
          <ServiceCard className="card" isClickedMobile={isActiveCard2}>
            <ColorIllustrationContainer className="ColorIllustrationContainer">
              <ColorIllustration className="ColorIllustration" src={Software} />
            </ColorIllustrationContainer>
            <Title className="Title">
              Software <br />{" "}
              <span style={{ whiteSpace: "nowrap" }}>Development</span>
            </Title>
            <Text className="Text">
              <NoWrapDiv>Web & Mobile</NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle className="SeparatorCircle" /> Cloud
                Architecture
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Agile Methodologies
              </NoWrapDiv>
              <NoWrapDiv>
                Quality Assurance
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Frontend
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Backend
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> CI/CD
              </NoWrapDiv>
            </Text>
            <ClickText className="ClickText">
              Engineer your brand digital presence with{" "}
              <NoWrapDiv>user-friendly</NoWrapDiv> and reliable software
              solutions, tailored to empower your business.
            </ClickText>
            <ClickTextImageContainer className="ClickTextImageContainer">
              <ClickTextImage src={Software } />
            </ClickTextImageContainer>
            <MoreIcon src={isActiveCard2? CloseImg :MoreImg}/>
          </ServiceCard>
        </CardContainer>
        <CardContainer isClickedMobile={isActiveCard3} onClick={() => setIsActiveCard3(!isActiveCard3)}>
          <ServiceCard className="card" isClickedMobile={isActiveCard3}>
            <ColorIllustrationContainer className="ColorIllustrationContainer">
              <ColorIllustration className="ColorIllustration" src={Growth} />
            </ColorIllustrationContainer>
            <Title className="Title">
              Growth & <br /> Marketing
            </Title>
            <Text className="Text">
              <NoWrapDiv>Social Media </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> SEO{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> SEM{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Paid User Acquisition{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                Social Listening{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Growth Consulting{" "}
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Analytics & Insights
              </NoWrapDiv>
              <NoWrapDiv>
                <SeparatorCircle /> Brand Engagement
              </NoWrapDiv>
            </Text>
            <ClickText className="ClickText">
              Increase awareness, generate engagement and drive sales to your
              business with impactful strategies and{" "}
              <NoWrapDiv>high-quality</NoWrapDiv> content campaigns.
            </ClickText>
            <ClickTextImageContainer className="ClickTextImageContainer">
              <ClickTextImage src={Growth} />
            </ClickTextImageContainer>
            <MoreIcon src={isActiveCard3 ? CloseImg :MoreImg}/>
          </ServiceCard>
        </CardContainer>
      </GridContainer>
    </Container>
  );
};
