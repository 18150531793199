import React from "react";

import number150 from "./assets/150.json";
import number193 from "./assets/193.json";
import infinite from "./assets/infinite.json";

import {
  Container,
  StatContainer,
  StatsContainer,
  StatText,
  NoWrapText,
} from "./styles";
import { LottiePlayer } from "../../components/commons/LottiePlayer";
import { CultureContainer } from "../../styles/commons";
import { useInView } from "react-hook-inview";

export const Stats = () => {

  const [ref, isVisible] = useInView({
    threshold: 0,
    rootMargin: '0%',
    unobserveOnEnter: true,
  })


  return (
    <Container ref={ref}>
      <CultureContainer
        paddingTop="60px"
        paddingTopTablet="60px"
        paddingBottomTablet="0px"
        paddingBottom="60px"
        paddingTopMobile="60px"
        paddingBottomMobile="0px"
      >
        
        <StatsContainer>
          <StatContainer>
              <LottiePlayer
                animation_width="auto"
                animation_height="80px"
                animation_data={number150}
                play={isVisible}
                keepLastFrame
                autoplay
              />
            <StatText>Annual Partner<br/>Projects</StatText>
          </StatContainer>
          <StatContainer>
              <LottiePlayer
                animation_width="auto"
                animation_height="80px"
                animation_data={infinite}
                play={isVisible}
                keepLastFrame
                autoplay
              />
            <StatText>Innovative<br/>Ideas</StatText>
          </StatContainer>
          <StatContainer>
              <LottiePlayer
                animation_width="auto"
                animation_height="80px"
                animation_data={number193}
                play={isVisible}
                keepLastFrame
                autoplay
              />
            <StatText>Average <NoWrapText>Partner ROI</NoWrapText></StatText>
          </StatContainer>
        </StatsContainer>
      </CultureContainer>
    </Container>
  );
};
