import styled from 'styled-components'

import {
  DESKTOP_1440,
  TABLET_768,
  TABLET_600,
  MOBILE_460,
} from '../../styles/sizes'

export const HeroContent = styled.div`
  position: relative;
  width: 100%;

  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`

export const GatsbyContainer = styled.div`
  width: 100%;

  @media (max-width: ${TABLET_768}px){
    display: none;
  }
`

export const GatsbyContainerMobile = styled.div`
  display: none;
  
  @media (max-width: ${TABLET_768}px){
    display: block;
    width: 100%;
  }
  @media (max-width: ${MOBILE_460}px){
    height: 500px;
  }
`

export const OpacityLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: black;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; 
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; 
`

export const TitlesSection = styled.div`
  width: 90%;
  max-width: 1200px;
  min-width: min(40vw, 1600px);
  position: absolute;
  top: 10vw;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${TABLET_600}px){
    top: 110px;
  }
  @media (max-width: ${MOBILE_460}px){
    top: 90px;
  }
`

export const Title = styled.h1`
  color: white;
  margin-top: 190px;
  font: bold 69px/83px "Montserrat";
  font-variation-settings: 'wght' 600;

  @media (max-width: ${DESKTOP_1440}px){
    font: bold 60px/70px "Montserrat";
  }
  @media (max-width: ${TABLET_600}px){
    font-size: 55px;
  }
  @media (max-width: ${MOBILE_460}px){
    font: bold 32px/40px "Montserrat";
    letter-spacing: 1px;
  }
`

export const SubTitle = styled.h2`
  color: white;
  font: 400 55px/65px "Montserrat";
  text-align: center;

  @media (max-width: ${DESKTOP_1440}px){
    font: 400 44px/52px "Montserrat";
  }
  @media (max-width: ${TABLET_600}px){
    font-size: 38px;
  }
  @media (max-width: ${MOBILE_460}px){
    font: 400 22px/30px "Montserrat";
  }
`

interface IDivProps {
  backgroundImage?: any,
}