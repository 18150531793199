import styled from 'styled-components'

export const LayoutContainer = styled.div<IDivProps>`
  width: 100%;
  margin: 0;
  font-family: "Montserrat";
  word-break: break-word;
  overflow-x: hidden;
  background: ${props => props.background ? props.background : "white"};
`

interface IDivProps {
  background?: string,
}
