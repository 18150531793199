import React from 'react'
import { Helmet } from 'react-helmet'
import { DOMAIN } from '../../config/urls'

const SEO:React.FC<IProps> = (props: IProps) => {
  return (
  <Helmet>
    <meta charSet="utf-8" />

    {
      props.pageTitle &&
      <title>{props.pageTitle}</title>
    }
    {
      props.robots &&
      <meta name="robots" content={props.robots} />
    }
    {
      props.description &&
      <meta name="description" content={props.description} />
    }
    {
      props.keywords &&
      <meta name="keywords" content={props.keywords} />
    }

    <meta name="twitter:card" content="summary" />

    {
      props.twitterSite &&
      <meta name="twitter:site" content={props.twitterSite} />
    }
    {
      props.tiwtterTitle &&
      <meta name="twitter:title" content={props.twitterTitle} />
    }
    {
      props.twitterImage &&
      <meta name="twitter:image" content={`${props.twitterImage}`}></meta>
    }
    {
      props.ogType &&
      <meta property="og:type" content={props.ogType} />
    }
    {
      props.ogTitlePage &&
      <meta property="og:title" content={props.ogTitlePage} />
    }
    {
      props.ogUrl &&
      <meta property="og:url" content={`${props.ogUrl}`} />
    }
    {
      props.ogImage &&
      <meta property="og:image" content={`${props.ogImage}`} />
    }
    {
      props.ogDescription &&
      <meta property="og:description" content={props.ogDescription} />
    }
    {
      props.ogSiteName &&
      <meta property="og:site_name" content={props.ogSiteName} />
    }
  </Helmet>
  )
}

export default SEO

interface IProps {
  [index:string] : string
}
