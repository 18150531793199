import React, { useEffect, useRef, useState } from "react";
import bodymovin, { AnimationItem } from "lottie-web";

import { LottieContainer } from "./styles";
import { CSSProperties } from "styled-components";
// import { ILottie } from '../../../interfaces'

export const LottiePlayer: React.FC<IProps> = (props: IProps) => {
  const containerRef = useRef(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);

  const onComplete = () => {
    !props.keepLastFrame && animation.goToAndStop(0);
    props.onComplete && props.onComplete();
  };

  useEffect(() => {
    // define lottie animation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    console.log("lottie", props.assetPath)
    const anim = bodymovin.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      autoplay: props.autoplay || false,
      loop: props.loop || false,
      animationData: props.animation_data,
      assetsPath: props.assetPath || ""
    })
    setAnimation(anim)
  }, [])

  useEffect(() => {
    if (animation) {
      animation.addEventListener("complete", onComplete);
      return () => animation.removeEventListener("complete", onComplete);
    }
  }, [animation]);

  useEffect(() => {
    if (animation) {
      if (props.play) {
        animation.play();
      } else {
        animation.stop();
      }
    }
  }, [props.play, animation]);

  return (
    <LottieContainer
      style={props.containerStyle}
      height={props.animation_height}
      width={props.animation_width}
      className={"lottie--container"}
      ref={containerRef}
      key={props.key || undefined}
    />
  );
};

interface IProps {
  animation_data: any;
  assetPath?: string; 
  loop?: boolean;
  autoplay?: boolean;
  animation_width?: string;
  animation_height?: string;
  play?: boolean;
  key?: string;
  keepLastFrame?: boolean;
  containerStyle?: CSSProperties;
  onComplete?: () => void;
}
