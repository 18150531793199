import React, { useEffect, useState } from "react";
// new Logos

import logo1 from "./assets/PSG.png";
import logo2 from "./assets/Reach.svg";
import logo3 from "./assets/Qualitas.svg";

import { Container, Image, PsgContainer } from "./styles";

export const BackedBy: React.FC = () => {
  return (
    <Container>
      <PsgContainer>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://www.psgequity.com/"
          target="_blank"
        >
          <Image src={logo1} />
        </a>
      </PsgContainer>
      <a
        style={{ textDecoration: "none", outline: "none" }}
        href="https://www.reachcapital.com/"
        target="_blank"
      >
        <Image src={logo2} />
      </a>
      <a
        style={{ textDecoration: "none", outline: "none" }}
        href="https://qualitasequity.com/"
        target="_blank"
      >
        <Image src={logo3} />
      </a>
    </Container>
  );
};
