import styled from "styled-components";
import {
  TABLET_992,
  TABLET_800,
  TABLET_600,
  MOBILE_460,
  MOBILE_380,
  DESKTOP_1024,
  MOBILE_500,
} from "../../styles/sizes";

import TextField from "@mui/material/TextField";

export const RecaptchaWrapper = styled.div`
  margin: 30px auto 30px auto;
`;

export const Text = styled.div`
  width: 100%;
  text-align: left;
  font: normal normal 500 24px/32px Montserrat;
  color: #ffffff;
  @media (max-width: ${TABLET_992}px) {
    text-align: center;
  }
  @media (max-width: ${TABLET_600}px) {
    font: normal 24px/28px "Montserrat";
  }
  @media (max-width: ${MOBILE_460}px) {
    text-align: left;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 10px solid #ff0000;
  background: #1a1a1d 0% 0% no-repeat padding-box;
`;

export const ContainerForm = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;

  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 90vw;
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: 35px;
  color: white;
  font: normal normal bold 50px/62px Montserrat;
  text-align: center;

  @media (max-width: 768px) {
    width: 75%;
    align-self: center;
  }
  
  @media (max-width: 600px) {
    margin-top: 15px;
    width: 80%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 221px;
  height: 195px;
  border: 2px solid #ff0000;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
  flex-wrap: wrap;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;

  @media (max-width: 1180px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 912px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 100%;
    align-self: center;
    flex-direction: column;
  }

  p.Mui-error {

      font-weight: 400;
      font-variation-settings: 'wght' 700;
      font-size: 15px;
      font: normal normal 400 14px/18px Montserrat !important;
  }

`;

export const CheckboxWrapper = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 60px;
`;

export const CheckboxInput = styled.input`
  appearance: none;
  margin-right: 5px;
  width: 27px;
  height: 27px;
  border: 1px solid #fff;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid #7971fa;
  }

  &:checked {
    background-color: #fff;
    position: relative;
  }

  &:checked::before {
    content: "✔";
    font-size: 25px;
    color: #fff;
    position: absolute;
    right: 3px;
    top: -5px;
    color: #000;
  }
`;

export const CheckboxText = styled.span`
  font: normal normal medium 15px/22px Montserrat;
  color: white;
  margin-right: 10px;
`;

export const FormMessage = styled.div`
  color: white;
  margin: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  @media (max-width: 1030px) {
    margin: 15px auto 0;
  }
`;

export const FormSuccesMessage = styled.div`
  width: 400px;
  height: 100px;
  border: 1px solid white;
  background: rgba(0, 0, 0);
  border-radius: 10px;
  position: fixed;
  top: calc(50vh - 50px);
  right: calc(50vw - 200px);
  z-index: 100;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  p{
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    line-height: 24px;
  }

  @media (max-width: ${MOBILE_500}px) {
    width: 90vw;
    right: 5vw;
    height: auto;
    p{
      width: 100%;
      text-align: left;
      font-weight: 500;
      font-size: 18px;
    }
    svg{
      width: 150px;
      height: 150px;

    }
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 10px;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: #d32f2f;
  font-weight: 400;
  font-variation-settings: 'wght' 700;
  font-size: 15px;
  font: normal normal 400 14px/18px Montserrat !important;
`;

export const CaptchaMessage = styled.div`
  margin-top: 10px;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: #d32f2f;
  font-weight: 400;
  font-variation-settings: 'wght' 700;
  font-size: 15px;
  font: normal normal 400 14px/18px Montserrat !important;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -20px;
`;


export const Button = styled.button`
  width: 251px;
  height: 63px;
  align-self: center;
  background: #ff0000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: white;
  padding: 12px 80px;
  border: none;
  font: normal normal bold 20px/28px Montserrat;
  white-space: nowrap;

  &:hover {
    background: #7971fa;
  }
`;

export const BottomSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  justify-content: center;
`;

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0px;
  }
`;

export const InputContainer = styled.div`
  width: 365px;
  height: 50px;
  display: flex;
  

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const NewInput = styled(TextField)({
  backgroundColor: "transparent !important",
  WebkitBoxShadow: "0 0 0 1000px transparent inset !important", // Override browser autocomplete styles
  "& label.Mui-focused": {
    color: "#fff",
  },


  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiInputBase-root": {
    color: "#fff", // Text color
  },
  "& .MuiFormHelperText-root": {
    position: 'absolute',
    bottom: '-17px',
  },
  "& .MuiInputBase-input": {
    color: "#fff", // Text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff", // Underline color
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#7971FA !important", // Underline color on hover
  },
  "& .MuiInputLabel-root": {
    color: "#fff !important", // Label color
    font: "normal normal bold 20px/28px Montserrat",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#fff", // Label color when focused
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff", // Border color for outlined variant
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#7971FA !important", // Border color on hover for outlined variant
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff", // Border color when focused for outlined variant
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff !important", // Underline color and hover color
    },

  },
});
