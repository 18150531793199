import React from "react";

import ThisIsUsLottie from "./assets/This_Is_Us_Panel_v01.json";

import {
  Text,
  Title,
  LottieInfoContainer,
  Bold,
  TextLottieContainer,
  TextContainer,
} from "./styles";
import { CultureContainer } from "../../styles/commons";
import { LottiePlayer } from "../../components/commons/LottiePlayer";


export const ThisIsUs = () => {
  return (

      <CultureContainer paddingTop="0" paddingTopTablet="45px">
        <TextLottieContainer>
          <TextContainer>
            <Title>This Is Us</Title>
            <Text>
              You won’t find an office here. You will find real people that work
              wherever they work best; professional, warm and close-knit people.
            </Text>
            <Text>
              We believe in the opportunities and advantages of working as we
              do.
            </Text>
            <Bold>100% remote work to optimize life balance.</Bold>
          </TextContainer>
          <LottieInfoContainer>
            <LottiePlayer
              play
              animation_width={"100%"}
              animation_data={ThisIsUsLottie}
              autoplay
              loop
              assetPath='/lottie/images/'
            />
          </LottieInfoContainer>
        </TextLottieContainer>
      </CultureContainer>

  );
};
