import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { DESKTOP_1920, MOBILE_320, MOBILE_380, MOBILE_460, MOBILE_500, TABLET_740, TABLET_992 } from '../../../styles/sizes';


export const FooterContainer = styled.footer`
  max-width: 100vw;
  background: black;
  color: white;
  padding-top: 65px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  @media (max-width: 990px){
    padding-top: 45px;
  } 
`;

export const TopContainer = styled.div`
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 990px){
    flex-direction: column;
    text-align: center;
  }
`;

export const FooterNav = styled.ul`
  display: flex;
  flex-direction: row;

  @media (max-width: 990px){
    flex-direction: column;
    margin-bottom: 10px;

    & > li {
      margin-bottom: 10px;
      @media (max-width: 990px) {
        margin-bottom: 20px;
      }

    }
  }

`
export const FooterLink = styled(AnchorLink)`
  font-family: "Montserrat";
  font-size: 15px;
  font-variation-settings: 'wght' 700;
  color: white; 
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 30px;
  border-bottom: 0px solid red;
  flex: 1;
  @media (max-width: 1098px) {
    margin-right: 15px;
    font-size: 15px;
  }
  @media (max-width: 990px) {
    font-variation-settings: 'wght' 300;
    margin-right: 0;
  }

  & :hover {
    transition: 0.25s;
    border-bottom: 2px solid red;
  }
`

export const FooterRedirectLink = styled.a`
  font-family: "Montserrat";
  font-size: 15px;
  font-variation-settings: 'wght' 700;
  color: white; 
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 30px;
  border-bottom: 0px solid red;
  flex: 1;
  @media (max-width: 1098px) {
    margin-right: 15px;
    font-size: 15px;
  }
  @media (max-width: 990px) {
    font-variation-settings: 'wght' 300;
    margin-right: 0;
  }

  & :hover {
    transition: 0.25s;
    border-bottom: 2px solid red;
  }
`

export const SocialList = styled(FooterNav)`
  display: flex;
  flex-direction: row;
  & > li {
    padding: 0 7px;
  }
  @media (max-width: 990px) {
    justify-content: center; 
  }
  
` 

export const SocialLinks = styled.a`


  margin-top: -10px;

  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;


  & > .behance {
    width: 17px;
    height: auto;
  }

  svg{
    filter: brightness(10);
    width: 100px;
    height: 100px;
  }

  & :hover {
    transition: 0.5s;
    border: 1px solid #7971FA;
    & > svg {
     filter: none;
    }
  }
  @media (max-width: 990px) {
    margin-top: 35px;
  }
`

export const StaticMiscellaneous = styled.img`
  position: absolute;
  width: 70%;
  height: 440px;
  left: 52vw;
  top: -30px;
  z-index: 0;
  opacity: 0.8;
  @media (max-width: ${TABLET_992}px) {
    top: 10vh;
    right: -209px;
    width: 100%;

  }
  @media (max-width: ${TABLET_740}px) {
    top:10vh;
    width: 100%;
  }
  @media (max-width: ${MOBILE_500}px) {
    top:15vh;
    width: 100%;
  }
  @media (max-width: ${MOBILE_460}px) {
    top:10vh;
    width: 100%;
  }
  @media (max-width: ${MOBILE_380}px) {
    top:25vh;
    width: 100%;
  }
`;


export const Copyright = styled.p`
    font-size: 12px;
    padding-top: 45px;
    padding-bottom: 107px;
    text-align: left;
    @media (max-width: 990px) {
      text-align:center;
      padding-top: 10px;
      padding-bottom: 35px;
    }
`