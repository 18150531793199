import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";

import { useIntl } from "gatsby-plugin-intl";

import SEO from "../components/SEO/SEO";

import { Layout } from "../components/Layout";

import { PeopleSlider } from "../sections/PeopleSlider";
import { AnimatedTitle } from "../components/commons/AnimatedTitle";

import {
  PageContainer,
  SectionTitle,
  CarrouselContainer,
  StaticMiscellaneous,
} from "../styles/about-us-revamp";
import { BackgroundWrapper, Container } from "../styles/commons";

import { Services } from "../sections/AboutUsServices";
import { AwardsCarrousel } from "../sections/Awards";
import { AboutUsContactForm } from "../sections/AboutUsContactForm";
import { BackedBy } from "../sections/BackedBy";
import { HomepageHero } from "../sections/homepageHero";
import { WorksSlider } from "../sections/WorksSlider";
import Logos from "../sections/Logos";

import patternHowWeDoIt from "../images/culture/miscellaneous/how-we-do-it.svg";
import patternOurVision from "../images/culture/miscellaneous/our-vision.svg";
import patternOurVision2 from "../images/culture/miscellaneous/our-vision-2.svg";


const homepage = ({ data }: any) => {
  const intl = useIntl();

  const defaultBackgroundColor = "white";
  const [backgroundColor, setBackgroundColor] = useState<string>(
    defaultBackgroundColor
  );

 

  return (
    <Layout initialTextColor="white" landing="about-us" backgroundColor="black">
      <SEO
        pageTitle={intl.formatMessage({ id: "culture_seo_title" })}
        description={intl.formatMessage({ id: "culture_seo_description" })}
        twitterSite="@wearepsh"
        twitterTitle={intl.formatMessage({ id: "culture_seo_title" })}
        ogUrl="https://wearepsh.com/about-us"
        ogDescription={intl.formatMessage({ id: "culture_seo_description" })}
        ogImage={"https://wearepsh.com/images/seo_image.png"}
      />
      <PageContainer id="ourCulture" >

        <HomepageHero />

        <BackgroundWrapper background="#EBEBEB">
          <Container paddingTop="40px" paddingTopMobile="40px" paddingBottom="55px" paddingBottomTablet="55px" paddingBottomMobile="55px">
            <AnimatedTitle height="53px" heightMobile="45px" lineDelay={0.3}>
              <SectionTitle id="scrollSection">What We Do</SectionTitle>
            </AnimatedTitle>
            <Services />
          </Container>
        </BackgroundWrapper>

        <BackgroundWrapper background="#1A1A1D">
          <Container
            paddingBottom="60px"
            paddingTop="60px"
            paddingTopMobile="30px"
            paddingBottomMobile="30px"
          >
            <Logos />
          </Container>
        </BackgroundWrapper>





        <CarrouselContainer style={{position: 'relative', background: 'white', zIndex: '1'}}>
        <StaticMiscellaneous
            style={{position: "absolute", width: "30%", top: "70px", right: "-12%", transform: "rotate(180deg)" }}
            src={patternHowWeDoIt}
          />
          <Container>
            <AnimatedTitle heightTablet='30px' height="40px" heightMobile="45px" lineDelay={0.3}>
              <SectionTitle smallText center id="awards" color={"#333333"}>
                Our Achievements
              </SectionTitle>
            </AnimatedTitle>
          </Container>
        </CarrouselContainer>
        <AboutUsContactForm></AboutUsContactForm>
      </PageContainer>
    </Layout>
  );
};

export default homepage;
