module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W5VZ73R3","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-125,"duration":650},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/locales","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PSH Site","short_name":"PSH Site","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/ms-icon-144x144.png","icons":[{"src":"/images/favIcons/ms-icon-310x310.png","sizes":"310x310","type":"image/png"},{"src":"/images/favIcons/ms-icon-150x150.png","sizes":"150x150","type":"image/png"},{"src":"/images/favIcons/ms-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/images/favIcons/ms-icon-70x70.png","sizes":"144x144","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a7b18da98dbb57f3b0f278f23a0bb498"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
