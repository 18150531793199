import styled, { css } from "styled-components";
import { Link } from "gatsby";

import {
  DESKTOP_1200,
  MOBILE_500,
  TABLET_992,
  TABLET_600,
  MOBILE_380,
  DESKTOP_1280,
  DESKTOP_1100,
} from "../../styles/sizes";

export const Container = styled.div`
  width: 100%;
  height: auto;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    justify-content: center;
    height: auto;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 3%;
  margin-top: 30px;

  @media (max-width: ${DESKTOP_1200}px) {
    gap: 2%;
  }

  @media (max-width: ${TABLET_992}px) {
    margin-bottom: 90px;
  }

  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: 1fr;
    width: 60%;
  }

  @media (max-width: ${TABLET_600}px) {
    width: 70%;
  }

  @media (max-width: ${MOBILE_500}px) {
    width: 100%;
  }
`;

export const CardContainer = styled.div<IdivProps>`
  .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    padding: 48px 18px 42px;
    position: relative;
    align-items: center;
    background-color: #F8F8F8;
    min-height: 520px;
    box-sizing: border-box;

    ${(props) =>
    props.isClickedMobile
      && css`
          padding-top: 53px;
          justify-content: space-between;

          .ColorIllustrationContainer {
            display: none;
          }
          .ClickTextImageContainer{
            display: flex;
          }
          .ClickText {
            display: block;
            opacity: 1;
          }
          .Title{
            margin-top: 0;
          }
          .Text {
            display: none;
          }
        `
     } 
    @media (max-width: ${DESKTOP_1280 + 5}px) {
      min-height: 540px;
    }
    @media (max-width: ${DESKTOP_1100}px) {
      min-height: 550px;
    }
    @media (max-width: ${TABLET_992}px) {
      min-height: 384px;
      .ClickTextImageContainer {
        justify-content:center;
      }
    }
    @media (max-width: ${MOBILE_380}px) {
      padding: 50px 20px;
    }
  }

  @media (min-width: ${TABLET_992}px) {
    &:hover {
      .card {
        .ColorIllustration {
          transform: scale(1.2);
        }
      }
    }
  }
`;

export const ServiceCard = styled.div<IdivProps>``;
export const ColorIllustration = styled.img`
  width: 100px;
  height: 95px;
  transition: transform 0.2s;

  @media (max-width: ${TABLET_992}px) {
    max-height: 191px;
    max-width: 100px;
  }
`;

export const ColorIllustrationContainer = styled.div`
  width: 124px;
  height: 117px;

  @media (max-width: ${TABLET_992}px) {
    max-height: 191px;
    max-width: 100px;
  }
`;


export const Title = styled.div`
  color: #000000;
  font: normal 800 34px/42px "Montserrat";
  margin-top: 36px;
  transition: all 0.2s;

  @media (max-width: ${TABLET_992}px) {
    margin-top: 16px;
  }
`;

export const Text = styled.div`
  color: #0d0d0d;
  margin-top: 22px;
  min-height: 130px;
  font: normal normal 300 18px/26px "Montserrat";
`;

export const ClickText = styled.div`
  font: normal normal 300 20px/28px "Montserrat";
  color: #0d0d0d;
  opacity: 0;
  display: none;
  overflow: hidden;
  padding-top: 20px;

  @media (max-width: ${DESKTOP_1280 + 20}px) {
    min-height: 164px;
  }
  @media (max-width: ${DESKTOP_1100}px) {
    min-height: 203px;
  }
  @media (max-width: ${TABLET_992}px) {
    padding-top: 15px;
    min-height: unset;
    
  }
`;

export const SeparatorCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin: 0 5px;
  background-color: #ff0000;
  vertical-align: middle;
  display: inline-block;
`;

export const NoWrapDiv = styled.div`
  white-space: nowrap;
  display: inline-block;
`;

export const MoreIcon = styled.img<IdivProps>`
  display: none;
  transition: all 0.3s ease-in-out;
  @media (max-width: ${TABLET_992}px) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 73px;
    height: 73px;
  }
`;

export const Redirect = styled(Link)`
  text-decoration: none;
  display: flex;
`;

export const ClickTextImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 84px;
  display: none;
  justify-content: flex-end;

  @media (max-width: ${DESKTOP_1100}px) {
    margin-top: 45px;
  }
`;

export const ClickTextImage = styled.img`
  width: 56px;
  height: 56px;
`;

export const MobileIconContainer = styled.img`
  width: 56px;
  height: 56px;
`;

interface IdivProps {
  isClickedMobile?: boolean;
  isClicked?: boolean;
}
