import React, { useEffect, useState } from "react";

import {
  Container,
  Title,
  Form,
  CheckboxWrapper,
  CheckboxInput,
  CheckboxText,
  FormMessage,
  ErrorMessage,
  BottomSection,
  Button,
  ContainerForm,
  NewInput,
  InputsContainer,
  InputContainer,
  RecaptchaWrapper,
  FormSuccesMessage,
  CaptchaMessage
} from "./styles";
import { CultureContainer } from "../../styles/commons";
import axios from "axios";
import { LottiePlayer } from "../../components/commons/LottiePlayer";

import checkAnimation from "./check-animation.json"

export const AboutUsContactForm = () => {
  const [inputs, setInputs] = useState({
    firstname: "",
    email: "",
    company: "",
    phone: "",
    message: "",
    accept_promotions: false,
  });
  const [errors, setErrors] = useState({
    firstname: true,
    email: true,
    company: false,
    phone: false,
    message: true,
    accept_promotions: false,
  });
  const [focusedInputs, setFocusedInputs] = useState({
    firstname: false,
    email: false,
    company: false,
    phone: false,
    message: false,
    accept_promotions: false,
  });
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [missingCaptcha, setMissingCaptcha] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, required: boolean) => {
    setSuccesForm(false);
    setErrorForm(false);
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    let hasError = !isTextValid(value, name) || (required && value === "")
    setErrors((errors) => ({ ...errors, [name]: hasError}));
    console.log({ ...errors, [name]: !isTextValid(event.target.value, name) })
  };

  const changeSetCheck = () => {
    setInputs((values) => ({ ...values, accept_promotions: !inputs.accept_promotions }));
  };

  const isTextValid = (text: string, type: string) => {
    switch (type) {
      case "email":
        return /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
          text
        )
          ? true
          : false;
      case "phone":
        if (
          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(text)
        ) {
          if (text.length > 7 && text.length < 18) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      default:
        return true;
    }
  };


  const handleBlur = (name: string) => {
    setFocusedInputs((focusedInputs) => ({ ...focusedInputs, [name]: true}));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!Object.values(errors).includes(true)) {
        try {
          const emailTestRegex = '^internal_automation_validation_contact_us_form_[\\w/-]+@test\\.com$';
          const regexPattern = new RegExp(emailTestRegex);
          var recaptchaResponse;
          if(inputs.email.match(regexPattern)) {
            recaptchaResponse = true;
          } else {
            recaptchaResponse = grecaptcha.getResponse();
          }
          
          if(recaptchaResponse){
            setMissingCaptcha(false);
            await axios.post("https://cms.wearepsh.com/api/leads-capture", {captchaResponse: recaptchaResponse, fields: inputs});
            setSuccesForm(true);
            setInputs({
              firstname: "",
              email: "",
              company: "",
              phone: "",
              message: "",
              accept_promotions: false,
            });
            setErrors({
              firstname: true,
              email: true,
              company: false,
              phone: false,
              message: true,
              accept_promotions: false,
            });
            setFocusedInputs({
              firstname: false,
              email: false,
              company: false,
              phone: false,
              message: false,
              accept_promotions: false,
            });

          } else {
            setMissingCaptcha(true)
          }

        } catch (error) {
          setErrorForm(true);
        }
    } else {
      e.preventDefault();
      setFocusedInputs({
        firstname: true,
        email: true,
        company: true,
        phone: true,
        message: true,
        accept_promotions: true,
      })

    }
  };


  const addExternalScript = (url: string) => {
    const externalScript = document.createElement('script');
    externalScript.src = url;
    externalScript.async=true;
    document.body.appendChild(externalScript);
 };


 useEffect(() => {
  const timer = setTimeout(() => {
    setErrorForm(false);
  }, 4000);

  // Clear the timeout if the component unmounts before the 4 seconds
  return () => clearTimeout(timer);
}, [errorForm, setErrorForm]); 

useEffect(() => {
  if(succesForm){
    const timer = setTimeout(() => {
      setSuccesForm(false);
    }, 2300);
  
    // Clear the timeout if the component unmounts before the 4 seconds
    return () => clearTimeout(timer);
  }
}, [succesForm, setSuccesForm]); 

  useEffect(() => {
    addExternalScript('https://www.google.com/recaptcha/api.js')
    // Create a script string with the JavaScript code
  }, []);

  return (
    <Container>
      <CultureContainer
        paddingTop="60px"
        paddingTopTablet={"60px"}
        paddingBottom="60px"
        paddingBottomTablet={"60px"}
      >
        <ContainerForm id="contacthome">
          <Title>Let’s Partner for Success</Title>
          <Form id="contact-form" onSubmit={handleSubmit} noValidate>
            <InputsContainer>
              <InputContainer>
                <NewInput
                  fullWidth
                  id="firstname"
                  label="Name"
                  variant="standard"
                  type="text"
                  name="firstname"
                  required={true}
                  onBlur={() => handleBlur("firstname")}
                  error={
                    focusedInputs.firstname &&
                    (!isTextValid(inputs.firstname, "firstname") || inputs.firstname === "")
                  }
                  helperText={
                    focusedInputs.firstname &&
                    (inputs.firstname === "" && "Required Field" || !isTextValid(inputs.firstname, "firstname") && "Invalid Name")
                  }
                  value={inputs.firstname || ""}
                  onChange={(e: any) => handleChange(e, true)}
                />
              </InputContainer>
              <InputContainer>
                <NewInput
                  fullWidth
                  label="Company"
                  variant="standard"
                  id="company"
                  type="text"
                  name="company"
                  required={false}
                  onBlur={() => handleBlur('company')}
                  value={inputs.company || ""}
                  onChange={(e: any) => handleChange(e, false)}
                />
              </InputContainer>
            </InputsContainer>
            <InputsContainer>
              <InputContainer>
                <NewInput
                  fullWidth
                  label="E-mail"
                  variant="standard"
                  id="email"
                  type="email"
                  name="email"
                  required={true}
                  value={inputs.email || ""}
                  onChange={(e: any) => handleChange(e, true)}
                  onBlur={() => handleBlur('email')}
                  error={
                    focusedInputs.email &&
                    (!isTextValid(inputs.email, "email") || inputs.email === "")
                  }
                  helperText={
                    focusedInputs.email &&
                    (inputs.email === "" && "Required Field" || !isTextValid(inputs.email, "email") && "Invalid Email")
                  }
                />
              </InputContainer>
              <InputContainer>
                <NewInput
                  fullWidth
                  label="Phone Number"
                  variant="standard"
                  id="phone"
                  type="phone"
                  name="phone"
                  required={false}
                  value={inputs.phone || ""}
                  onChange={(e: any) => handleChange(e, false)}
                  onBlur={() => handleBlur("phone")}
                  error={
                    focusedInputs.phone &&
                    inputs.phone !== "" &&
                    !isTextValid(inputs.phone, "phone")
                  }
                  helperText={
                    focusedInputs.phone &&
                    !isTextValid(inputs.phone, "phone") &&
                    inputs.phone !== "" &&
                    "Invalid Phone Number"
                  }
                />
              </InputContainer>
            </InputsContainer>
            <NewInput
              fullWidth
              label="Message"
              variant="standard"
              id="message"
              type="text"
              name="message"
              required={true}
              onBlur={() => handleBlur("message")}
              error={
                focusedInputs.message &&
                (!isTextValid(inputs.message, "message") || inputs.message === "")
              }
              helperText={
                focusedInputs.message &&
                (inputs.message === "" && "Required Field" || !isTextValid(inputs.message, "message") && "Invalid Message")
              }
              value={inputs.message || ""}
              onChange={(e: any) => handleChange(e, true)}
              multiline
              rows={4}
            />
            <CheckboxWrapper>
              <CheckboxInput
                type="checkbox"
                checked={inputs.accept_promotions}
                onChange={changeSetCheck}
              />
              <CheckboxText>I agree to receive information on behalf of PSh</CheckboxText>
            </CheckboxWrapper>
            <BottomSection>
            <RecaptchaWrapper>
            <div className="g-recaptcha" data-sitekey="6LfFsTEpAAAAAC-fB-NXshcZi7NUtBYiww_5--S_" data-callback='recaptchacallback'></div>
            </RecaptchaWrapper>
            {missingCaptcha && (
              <CaptchaMessage>
                Please complete Captcha before continuing
              </CaptchaMessage>
            )}
              <Button type="submit"> LET´S GO </Button>
              {succesForm ? (
                <FormSuccesMessage>
                  
                  <LottiePlayer 
                  keepLastFrame         
                animation_data={checkAnimation}
                autoplay
                play
            />
            <p>
            Information Sent Successfully
            </p>
                </FormSuccesMessage>
              ) : (
                errorForm && (
                  <FormMessage>Error. Please try again later</FormMessage>
                )
              )}
            </BottomSection>
          </Form>
        </ContainerForm>
      </CultureContainer>
    </Container>
  );
};
