import React from "react";
import { GatsbyImage, getImage} from 'gatsby-plugin-image';

import {HeroContent, TitlesSection, Title, SubTitle, GatsbyContainer, GatsbyContainerMobile, Video, OpacityLayer, Image} from './styles';

export const ClientHero : React.FC<IHero> = (props: IHero) => {

  const gatsbyBackground = props.heroMedia.urlSharp?.childImageSharp ? getImage(props.heroMedia.urlSharp) : props.heroMedia.url

  const gatsbyBackgroundMobile = props.heroMediaMobile.urlSharp?.childImageSharp ? getImage(props.heroMediaMobile.urlSharp) : props.heroMediaMobile.url

  return (
    <HeroContent>
        <GatsbyContainer>
          {typeof gatsbyBackground === 'string' ? 
            (
              <>
              { gatsbyBackground.includes('.mp4') ? 
                <>
                  <OpacityLayer />
                  <Video autoPlay loop muted playsInline>
                    <source src={gatsbyBackground} type="video/mp4" />
                  </Video>
                </>
                :
                <>
                  <Image src={gatsbyBackground} alt="" />
                </>
              }
              </>
            )
          : 
            (
              <GatsbyImage image={gatsbyBackground} alt={'hero'} />
            )
          }
        </GatsbyContainer>
        <GatsbyContainerMobile>
          {typeof gatsbyBackgroundMobile === 'string' ? 
            (
              <>
                { gatsbyBackgroundMobile.includes('.mp4') ? 
                  <>
                    <OpacityLayer />
                    <Video autoPlay loop muted playsInline>
                      <source src={gatsbyBackgroundMobile} type="video/mp4" />
                    </Video>
                  </>
                  :
                  <>
                    <Image src={gatsbyBackgroundMobile} alt="" />
                  </>
                }
              </>
            )
            : 
            (
              <GatsbyImage image={gatsbyBackgroundMobile} alt={'hero'} />
            )
          }
        </GatsbyContainerMobile>
      <TitlesSection>
        <Title>{props.Project_Title}</Title>
        <SubTitle>{props.subtitle}</SubTitle>
      </TitlesSection>
    </HeroContent>
  )
}

interface IHero {
  Project_Title?: string;
  subtitle?: string;
  heroMedia?: {
    url: string;
    urlSharp: any;
  };
  heroMediaMobile?: {
    url: string;
    urlSharp: any;
  };
}
  