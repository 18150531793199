import React, { ReactNode } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import MouseContextProvider from '../../context/mouseContext'

export const Root: React.FC<IProps> = (props: IProps) => {
  return (
        <MouseContextProvider>
            {props.children}

        </MouseContextProvider>
  )
}

export default Root

interface IProps {
    data: { strapi: { theme: {
        primary_color: string,
        secondary_color: string,
        text_color: string,
        dark_background: string,
        light_background: string
    }}}

    children: ReactNode
}
