import React from "react";

import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

import TwitterLogo from "../../../images/social-icons/twitter-brands";
import BehanceLogo from "../../../images/social-icons/behance-brands";
import InstagramLogo from "../../../images/social-icons/instagram-brands";
import LinkedinLogo from "../../../images/social-icons/linkedin-in-brands";
import footerPattern from "../../../images/culture/miscellaneous/trama-footer.svg";


import {
  FooterContainer,
  TopContainer,
  FooterNav,
  FooterLink,
  SocialList,
  FooterRedirectLink,
  SocialLinks,
  Copyright,
  StaticMiscellaneous,
} from "./styles";

import { LargeContainer } from "../../../styles/commons";

const date = new Date();

export const Footer: React.FC<IProps> = () => {
  const intl = useIntl();

  return (
    <FooterContainer>
      <StaticMiscellaneous
        src={footerPattern}
      />
      <LargeContainer>
        <TopContainer>
          <FooterNav>
            <li><FooterLink to="/#hero"> <FormattedMessage id="home" /></FooterLink></li>
            <li><FooterLink to="/#scrollSection" ><FormattedMessage id="services" /></FooterLink></li>
            <li><FooterRedirectLink href="/work/" ><FormattedMessage id="work" /></FooterRedirectLink></li>
            <li><FooterLink to="/about-us" ><FormattedMessage id="culture" /></FooterLink></li>
            <li><FooterLink to="/#awards" ><FormattedMessage id="awards" /></FooterLink></li>
            <li><FooterRedirectLink href="https://sites.google.com/wearepsh.com/pshrecruiting/inicio" target="_blank"><FormattedMessage id="careers" /></FooterRedirectLink></li>
            <li><FooterRedirectLink href="/notice/privacy-policy" target="_blank">Privacy Policy</FooterRedirectLink></li>
          </FooterNav>
          <SocialList>
            <li><SocialLinks href="https://www.linkedin.com/company/wearepsh/" target="_blank"><LinkedinLogo /> </SocialLinks></li>
            <li><SocialLinks href="https://www.instagram.com/wearepsh/" target="_blank"> <InstagramLogo /> </SocialLinks></li>
            <li><SocialLinks href="https://twitter.com/wearepsh/" target="_blank"> <TwitterLogo /> </SocialLinks></li>
            <li><SocialLinks href="https://www.behance.net/wearepsh" target="_blank"> <BehanceLogo /> </SocialLinks></li>
          </SocialList>
        </TopContainer>
    
        <Copyright>
          Copyright &copy; { date.getFullYear() } - PSh | All rights reserved.
        </Copyright>

      </LargeContainer>
    </FooterContainer>
  );
};

interface IProps {}
