import styled from "styled-components";
import {
  TABLET_992,
  TABLET_600,
  TABLET_570,
  DESKTOP_1200,
  DESKTOP_1024,
} from "../../styles/sizes";

export const Text = styled.div`
  width: 100%;
  text-align: left;
  font: normal normal 500 24px/32px Montserrat;
  color: #ffffff;
  @media (max-width: ${TABLET_992}px) {
    text-align: center;
  }
  @media (max-width: ${TABLET_600}px) {
    font: normal 20px/30px "Montserrat";
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000000 0% 0% no-repeat padding-box;
`;

export const ContentContainer = styled.div`
  @media (max-width: ${DESKTOP_1200}px) {
  }
`;

export const Title = styled.div`
  margin-bottom: 35px;
  color: white;
  font: normal normal bold 45px/55px Montserrat;
  text-align: left;
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0;
    align-self: center;
    text-align: center;
    padding-bottom: 25px;
    font: normal normal bold 38px/40px Montserrat;
  }
`;

export const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  position: absolute;

  transition: opacity; 0.5s;
  top: 20px;
`;

export const HoverContainer = styled.div`
  opacity: 0;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50px;

  transition-delay: 0s;
  transition-property: all;
  transition-duration: 0.3s;
`;

export const IconTitle = styled.div`
  color: white;
  position: absolute;
  bottom: 25px;
  text-align: center;
  margin-top: 10px;
  font: normal normal bold 20px/28px Montserrat;
  transition: transform 0.5s;
`;



export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
  flex-wrap: nowrap;

  @media (max-width: ${DESKTOP_1200}px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 30px;

  transition-delay: 0s;
  transition-property: all;
  transition-duration: 0.5s;
`;

export const IconText = styled.div`
  width: 90%;
  margin: 15px auto 0 auto;
  color: white;
  text-align: center;
  font: normal normal 500 15px/22px Montserrat;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 221px;
  cursor: default;
  height: 195px;
  border: 2px solid #ff0000;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-right: 15px;
  margin-left: 15px;
  transition: border-color 0.3s;
  position: relative;

  &:hover {
    border-color: #7971fa;
    justify-content: flex-start;

    ${Icon} {
      transform: scale(0);
      opacity: 0;
      transition-delay: 0s;
    }    

    ${HoverContainer} {
      opacity: 1;
      transition-delay: 0.3s;
    }
    ${IconTitle} {
      margin-top: 25px;
      transform: translateY(-110px);
    }
  }

  @media (max-width: ${TABLET_570}px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;
