import React from "react";

import {MarkdownParser} from '../../components/Markdown'
import { CMSParseImage } from "../../components/commons/ImageParser";

import {Container, Website, InfoContainer, TitlesSection, ClientTitle, InfoSection, Categories, LogoSection } from './styles';

export const ClientInfo : React.FC<IClientInfo> = (props:IClientInfo) => {
  const CategoriesArray = props.Categories.split(',');

  return (
    <Container>
      <InfoContainer>
        <TitlesSection>
          <ClientTitle isBigger>Partner</ClientTitle>
          <LogoSection>{CMSParseImage(props.clientLogo)}</LogoSection>
          { props.Industry &&
            <>
              <ClientTitle hasMarginTop>Industry</ClientTitle>
              <Categories>{props.Industry}</Categories>
            </>
          }
          <ClientTitle hasMarginTop>Website</ClientTitle>
          <Website href={props.website} target="_blank">{props.website.replace('https://','').replace('www.', '').replace('.com/','.com')}</Website>
          <ClientTitle hasMarginTop>Categories</ClientTitle>
          <Categories>{CategoriesArray.join("\r\n")}</Categories>
        </TitlesSection>
        <InfoSection><MarkdownParser data={props.info}/></InfoSection>
      </InfoContainer>
    </Container>
  )
}

interface IClientInfo {
  website?: string,
  info?: any,
  Categories?: any,
  Industry: any,
  clientLogo?: any
}
  